import React, { useEffect, useState } from "react";
import "./TuitionClasses.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import { get } from "../../redux/ApiHelper";
import { TuitionClassInterface } from "../../interfaces/tuitionClass.interface";
import { TextField } from "@mui/material";

const TuitionClasses: React.FC = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("all");
  const [dataFetched, setDataFetched] = useState<Record<string, boolean>>({
    all: false,
    ongoing: false,
    today: false,
    upcoming: false,
    history: false,
  });
  const [classes, setClasses] = useState<
    Record<string, TuitionClassInterface[]>
  >({
    all: [],
    ongoing: [],
    today: [],
    upcoming: [],
    history: [],
  });

  const [filter, setFilter] = useState(""); // Search input (Teacher, Student, Parent)
  const [filteredClasses, setFilteredClasses] =
    useState<Record<string, TuitionClassInterface[]>>(classes); // Filtered classes after applying date or search filters
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // Selected date from Date Picker

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (!dataFetched[newValue]) {
      fetchClasses(newValue);
    }
  };

  // Fetch classes from API
  const fetchClasses = (tab: string) => {
    dispatch(startLoading());

    const apiUrl = `/admin/tuition/schedules${
      tab !== "all" ? `?filter=${tab}` : ""
    }`; // Dynamic API URL

    get(apiUrl)
      .then((response) => {
        setClasses((prevClasses) => ({
          ...prevClasses,
          [tab]: response, // Save fetched data for the specific tab
        }));
        setFilteredClasses((prevClasses) => ({
          ...prevClasses,
          [tab]: response, // Save fetched data for the specific tab
        }));
        setDataFetched((prevFetched) => ({
          ...prevFetched,
          [tab]: true, // Mark tab data as fetched
        }));
      })
      .catch((error) => console.error(`Error fetching ${tab} data:`, error))
      .finally(() => dispatch(stopLoading()));
  };

  useEffect(() => {
    fetchClasses("all"); // Fetch default tab data on load
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setFilter(searchTerm);
    filterClasses(searchTerm, selectedDate);
  };

  // Function to handle Date Picker change
  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
    filterClasses(filter, newDate);
  };

  // Filter function to check date (today, upcoming, past) and search term (teacher, student, parent)
  const filterClasses = (searchTerm: string, dateFilter: Date | null) => {
    const filtered: any = classes[value]?.filter((classData) => {
      // If searchTerm is empty, return true for all classes (show all)
      if (!searchTerm.trim()) {
        return true; // This means no filtering by search term
      }

      // Check if class matches the search term (teacher, student, or parent name)
      const matchesSearch =
        classData.teacher.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        classData.student.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        classData.parent.name.toLowerCase().includes(searchTerm.toLowerCase());

      // Check if class matches the date filter
      const matchesDateFilter = dateFilter
        ? new Date(classData.tuitionClassSchedule.date).toDateString() ===
          dateFilter.toDateString()
        : true;

      return matchesSearch && matchesDateFilter;
    });

    // If no classes are found after filtering, return an empty array to avoid displaying 'no classes'
    setFilteredClasses({ ...filteredClasses, [value]: filtered });
  };

  useEffect(() => {
    filterClasses(filter, selectedDate); // Re-filter classes when value or selectedDate changes
    // eslint-disable-next-line
  }, [value, selectedDate, filter]);

  return (
    <div className="tuition-classes flex flex-col flex-grow overflow-hidden">
      <div className="title">Tuition Classes</div>
      <div className="container flex flex-col flex-grow overflow-hidden">
        <TabContext value={value}>
          <Box
            className="mb-3"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              onChange={handleTabChange}
              aria-label="Tuition Classes Tabs"
              sx={{
                "& .MuiTab-root": {
                  color: "var(--color-light-gray)",
                  "&:hover": {
                    color: "var(--color-primary)!important",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px 8px 0 0",
                  },
                },
                "& .Mui-selected": {
                  color: "var(--color-primary)!important",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  fontWeight: "bold",
                  borderRadius: "8px 8px 0 0",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--color-primary)",
                },
              }}
            >
              <Tab label="All" value="all" />
              <Tab label="Ongoing" value="ongoing" />
              <Tab label="Today" value="today" />
              <Tab label="Upcoming" value="upcoming" />
              <Tab label="History" value="history" />
            </TabList>
          </Box>
          <div className="flex gap-4">
            {/* Search Bar */}
            <div className="search-bar mb-3 p-4 bg-gray-200 flex-grow">
              <input
                type="text"
                placeholder="Search by Teacher, Student, or Parent..."
                value={filter}
                onChange={handleSearchChange}
                className="p-2 w-full rounded-md border border-gray-300"
              />
            </div>

            {/* Date Filter */}
            <div className="date-filter mb-3 p-4 bg-gray-200 flex-grow">
              <TextField
                type="date"
                value={
                  selectedDate ? selectedDate.toISOString().split("T")[0] : ""
                }
                onChange={(e) => handleDateChange(new Date(e.target.value))}
                className="w-full p-2 rounded-md"
              />
            </div>

            {/* Reset Button */}
            <div className="reset-button mb-3 p-4 flex items-center">
              <button
                onClick={() => {
                  setFilter(""); // Clear search term
                  setSelectedDate(null); // Clear selected date
                  filterClasses("", null); // Reset filter
                }}
                className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
              >
                Reset
              </button>
            </div>
          </div>

          {["all", "ongoing", "today", "upcoming", "history"].map((tab) => (
            <TabPanel
              key={tab}
              value={value} // Ensure it matches the TabContext value
              className="flex flex-col flex-grow overflow-auto p-2 bg-gray-100 rounded"
              style={{ display: value === tab ? "flex" : "none" }} // Show only active tab
            >
              <div className="flex flex-col gap-4  p-4">
                {filteredClasses[tab]?.length > 0 ? (
                  filteredClasses[tab]?.map((classData) => (
                    <Card
                      key={classData._id}
                      className="mb-3 shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
                    >
                      <CardContent>
                        {/* Tuition Class Info */}
                        <div>
                          <h2 className="text-xl font-semibold">
                            Class on{" "}
                            {classData?.tuitionClassSchedule?.date
                              ? new Date(
                                  classData?.tuitionClassSchedule?.date
                                )?.toLocaleDateString("en-IN")
                              : "Not updated yet"}
                          </h2>
                          {classData?.tuitionClassSchedule?.from && (
                            <p className="text-gray-600 mt-2">
                              Time: {classData.tuitionClassSchedule.from} -{" "}
                              {classData.tuitionClassSchedule.to}
                            </p>
                          )}

                          {classData?.subjects?.length > 0 && (
                            <p className="text-gray-600">
                              Subject(s): {classData?.subjects?.join(", ")}
                            </p>
                          )}
                        </div>

                        <div className="flex flex-col md:flex-row gap-4">
                          {/* Teacher Info */}
                          <div className="flex  mt-3 w-full md:w-1/3 text-center md:text-left">
                            <div className="flex justify-center md:justify-start ">
                              <img
                                src={classData.teacher.profilePicture}
                                alt={classData.teacher.name}
                                className="rounded-full w-12 h-12 mr-3 border border-solid border-yellow-600"
                              />
                              <div>
                                <h3 className="text-lg font-semibold text-blue-600">
                                  Teacher
                                </h3>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Name:
                                  </span>{" "}
                                  {classData.teacher.name}
                                </p>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Subjects:
                                  </span>{" "}
                                  {classData.teacher.subjects.join(", ")}
                                </p>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Languages:
                                  </span>{" "}
                                  {classData.teacher.languages?.join(", ")}
                                </p>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Address:
                                  </span>{" "}
                                  {[
                                    classData.teacher?.address?.addressLine,
                                    classData.teacher?.address?.city,
                                    classData.teacher?.address?.state,
                                    classData.teacher?.address?.pinCode,
                                  ]
                                    .filter(Boolean) // Filters out undefined, null, or empty string values
                                    .join(", ")}
                                </p>
                                {classData.teacherAttendanceRequest && (
                                  <p className="mt-1">
                                    Attendance Status:{" "}
                                    <span
                                      className={
                                        classData.isTeacherPresent
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }
                                    >
                                      {classData.isTeacherPresent
                                        ? "Present"
                                        : "Not Present"}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Student Info */}
                          <div className="flex  mt-3 w-full md:w-1/3 text-center md:text-left">
                            <div className="flex justify-center md:justify-start ">
                              <img
                                src={classData.student.profilePicture}
                                alt={classData.student.name}
                                className="rounded-full w-12 h-12 mr-3 border border-solid border-yellow-600"
                              />
                              <div>
                                <h3 className="text-lg font-semibold text-blue-600">
                                  Student
                                </h3>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Name:
                                  </span>{" "}
                                  {classData.student.name}
                                </p>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Grade:
                                  </span>{" "}
                                  {classData.student.grade}
                                </p>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    Board:
                                  </span>{" "}
                                  {classData.student.board}
                                </p>
                                <p className="text-gray-600">
                                  <span className="font-semibold text-yellow-500">
                                    School:
                                  </span>{" "}
                                  {classData.student.institute}
                                </p>

                                {classData.teacherAttendanceRequest && (
                                  <p className="mt-1">
                                    Attendance Status:{" "}
                                    <span
                                      className={
                                        classData.isStudentPresent
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }
                                    >
                                      {classData.isStudentPresent
                                        ? "Present"
                                        : "Not Present"}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Parent Info */}
                          <div className="mt-3 w-full md:w-1/3 text-center md:text-left">
                            <div>
                              <h3 className="text-lg font-semibold text-blue-600">
                                Parent
                              </h3>
                              <p className="text-gray-600">
                                <span className="font-semibold text-yellow-500">
                                  Name:
                                </span>{" "}
                                {classData.parent.name}
                              </p>
                              <p className="text-gray-600">
                                <span className="font-semibold text-yellow-500">
                                  Phone:
                                </span>{" "}
                                {classData.parent.phoneNumber}
                              </p>
                              <p className="text-gray-600">
                                <span className="font-semibold text-yellow-500">
                                  Email:
                                </span>{" "}
                                {classData.parent.email}
                              </p>
                              <p className="text-gray-600">
                                <span className="font-semibold text-yellow-500">
                                  Address:
                                </span>{" "}
                                {[
                                  classData.parent?.address?.addressLine,
                                  classData.parent?.address?.city,
                                  classData.parent?.address?.state,
                                  classData.parent?.address?.pinCode,
                                ]
                                  .filter(Boolean) // Filters out undefined, null, or empty string values
                                  .join(", ")}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* Attendance Info */}

                        {/* {classData?.teacherAttendanceRequest && ( */}
                        <div className="mt-3 flex flex-col md:flex-row md:justify-around gap-4">
                          {/* Teacher Attendance Request */}
                          <div className="mb-4 md:mb-0">
                            <p className="text-center font-semibold my-2">
                              Teacher Attendance Request
                            </p>
                            <p className="text-gray-600 text-left">
                              <span className="text-blue-700 mr-2">
                                Status:
                              </span>
                              <span
                                className={
                                  "ml-2 " +
                                  (classData?.teacherAttendanceRequest
                                    ?.status === "pending"
                                    ? "text-yellow-500"
                                    : classData?.teacherAttendanceRequest
                                        ?.status === "approved"
                                    ? "text-green-500"
                                    : "text-red-500")
                                }
                              >
                                {classData?.teacherAttendanceRequest?.status?.toUpperCase() ||
                                  "Not Available"}
                              </span>
                            </p>
                            <p className="text-gray-600 text-left">
                              <span className="text-blue-700 mr-2">
                                Request Time :
                              </span>
                              <span>
                                {classData?.teacherAttendanceRequest
                                  ?.requestTime ? (
                                  new Date(
                                    classData?.teacherAttendanceRequest?.requestTime
                                  ).toLocaleString("en-IN", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                ) : (
                                  <span className="text-red-500">
                                    Not Available
                                  </span>
                                )}
                              </span>
                            </p>
                            {classData?.teacherAttendanceRequest
                              ?.approveOrRejectTime && (
                              <p className="text-gray-600 text-left">
                                {classData?.teacherAttendanceRequest?.status ===
                                "approved"
                                  ? "Approval"
                                  : "Rejection"}{" "}
                                <span className="text-blue-700 mr-2">
                                  Time :
                                </span>
                                <span>
                                  {classData?.teacherAttendanceRequest
                                    ?.approveOrRejectTime &&
                                    new Date(
                                      classData?.teacherAttendanceRequest?.approveOrRejectTime
                                    ).toLocaleString("en-IN", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                </span>
                              </p>
                            )}
                          </div>

                          {/* Student Attendance Request */}
                          <div className="mb-4 md:mb-0">
                            <p className="text-center font-semibold my-2">
                              Student Attendance Request
                            </p>
                            <p className="text-gray-600 text-left">
                              <span className="text-blue-700 mr-2">
                                Status:
                              </span>
                              <span
                                className={
                                  classData?.studentAttendanceRequest
                                    ?.status === "pending"
                                    ? "text-yellow-500"
                                    : classData?.studentAttendanceRequest
                                        ?.status === "approved"
                                    ? "text-green-500"
                                    : "text-red-500"
                                }
                              >
                                {classData?.studentAttendanceRequest?.status?.toUpperCase() ||
                                  "Not Available"}
                              </span>
                            </p>
                            <p className="text-gray-600 text-left">
                              <span className="text-blue-700 mr-2">
                                Request Time :
                              </span>
                              <span>
                                {classData?.studentAttendanceRequest
                                  ?.requestTime ? (
                                  new Date(
                                    classData?.studentAttendanceRequest?.requestTime
                                  ).toLocaleString("en-IN", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                ) : (
                                  <span className="text-red-500">
                                    Not Available
                                  </span>
                                )}
                              </span>
                            </p>
                            {classData?.studentAttendanceRequest
                              ?.approveOrRejectTime && (
                              <p className="text-gray-600 text-left">
                                {classData?.studentAttendanceRequest?.status ===
                                "approved"
                                  ? "Approval"
                                  : "Rejection"}{" "}
                                <span className="text-blue-700 mr-2">
                                  Time :
                                </span>
                                <span>
                                  {classData?.studentAttendanceRequest
                                    ?.approveOrRejectTime &&
                                    new Date(
                                      classData?.studentAttendanceRequest?.approveOrRejectTime
                                    ).toLocaleString("en-IN", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                        {/* )} */}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    color="text.secondary"
                    className="text-center mt-4"
                  >
                    No classes available.
                  </Typography>
                )}
              </div>
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </div>
  );
};

export default TuitionClasses;
