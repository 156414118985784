import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "../../redux/ApiHelper";
import { TeacherInterface } from "../../interfaces/teacher.interface";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import { errorToast, successToast, warningToast } from "../../utils/toast.util";
import { FaFilePdf } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const TeacherDetails = () => {
  const dispatch: DispatchType = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [teacher, setTeacher] = useState<TeacherInterface | null>(null);

  useEffect(() => {
    dispatch(startLoading());
    get(`/teacher/profile/${id}`)
      .then((res) => setTeacher(res))
      .catch(() => {
        errorToast("Something went wrong! Please try again later");
      })
      .finally(() => {
        dispatch(stopLoading());
      });
    //eslint-disable-next-line
  }, [id]);

  const handleVerify = async (
    field: "highestQualification" | "otherDocs" | "aadhar" | "pan",
    docIndex?: number
  ) => {
    if (!field || (field === "otherDocs" && docIndex === null)) {
      warningToast("Please provide all necessary information.");
      return;
    }
    dispatch(startLoading());
    await put(`/admin/teacher-verification/verify/${teacher?._id}`, {
      field,
      docIndex,
    })
      .then((res) => {
        successToast(res.message);
        setTeacher(res.data);
      })
      .catch(() => {
        errorToast("Error updating verification status");
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  if (!teacher) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-xl font-semibold text-gray-700">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex-grow flex flex-col p-4 overflow-auto teacher-details">
      <div className="flex flex-col h-full rounded-lg shadow-lg flex-grow">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row items-center md:items-start gap-8 p-4 h-fit">
          {/* Profile Image */}
          <img
            src={teacher.profilePicture}
            alt={`${teacher.name}'s profile`}
            className="w-32 h-32 rounded-full shadow-md object-cover border-4 border-yellow-500"
          />
          {/* Profile Overview */}
          <div>
            <h1 className="text-3xl font-bold text-yellow-500 text-left">
              {teacher.name}
            </h1>

            <p className="text-gray-200 mt-2">
              {teacher?.about || "No profile overview available."}
            </p>
          </div>
        </div>
        <div className="md:flex flex-grow overflow-y-auto p-4 gap-4">
          {/* Additional Sections */}
          <div className="w-full md:w-50 text-left">
            {/* Highest Qualification */}
            <h2 className="text-xl font-semibold text-yellow-500">
              Highest Qualification
            </h2>
            <div className="mt-4 space-y-3">
              {teacher?.highestQualification ? (
                <div className="text-gray-200 space-y-2">
                  <p>
                    <span className="font-semibold text-yellow-400">
                      Institute:
                    </span>{" "}
                    {teacher?.highestQualification?.instituteName}
                  </p>
                  <p>
                    <span className="font-semibold text-yellow-400">
                      Qualification:
                    </span>{" "}
                    {teacher?.highestQualification?.qualificationLabel}
                  </p>
                  <p>
                    <span className="font-semibold text-yellow-400">
                      Field of Study:
                    </span>{" "}
                    {teacher?.highestQualification?.fieldOfStudy}
                  </p>
                  <p>
                    <span className="font-semibold text-yellow-400">
                      Year of Completion:
                    </span>{" "}
                    {teacher?.highestQualification?.yearOfCompletion}
                  </p>
                  <p>
                    <span className="font-semibold text-yellow-400">
                      Grade/Percentage:
                    </span>{" "}
                    {teacher?.highestQualification?.gradeOrPercentage}
                  </p>
                  <p>
                    <span className="font-semibold text-yellow-400">
                      Degree:
                    </span>{" "}
                    {teacher?.highestQualification?.degreeName}
                  </p>
                  <div className="flex flex-col">
                    <p>
                      <span className="font-semibold text-yellow-400">
                        Certificate:
                      </span>
                    </p>
                    <a
                      href={teacher?.highestQualification?.docUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-2"
                    >
                      {/* Conditional icon/image based on file type */}
                      {teacher?.highestQualification?.docUrl
                        ?.toLowerCase()
                        .endsWith(".pdf") ? (
                        <div>
                          <FaFilePdf className="text-red-400" />
                          <p>View PDF document</p>
                        </div>
                      ) : (
                        <img
                          src={teacher?.highestQualification?.docUrl}
                          alt="Document thumbnail"
                          className="highest-qualification-img object-cover rounded-sm"
                        />
                      )}
                    </a>
                  </div>
                  {teacher?.highestQualification?.isVerified ? (
                    <p>
                      <span>Verified</span>{" "}
                      <IoMdCheckmarkCircleOutline className="text-green-500 ml-2" />
                    </p>
                  ) : (
                    <button
                      className="px-4 py-2 mt-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={() => handleVerify("highestQualification")}
                    >
                      Verify Qualification
                    </button>
                  )}
                </div>
              ) : (
                <p className="text-gray-200">
                  No highest qualification available.
                </p>
              )}
            </div>

            {/* Other Documents */}
            {teacher?.otherDocs?.length > 0 && (
              <div>
                <h2 className="text-xl font-semibold text-yellow-500">
                  Other Documents
                </h2>
                <div className="mt-4 space-y-4">
                  {teacher.otherDocs.map((doc, index) => (
                    <div key={index} className="flex flex-col gap-2">
                      <a
                        href={teacher?.highestQualification?.docUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mt-2"
                      >
                        {/* Conditional icon/image based on file type */}
                        {teacher?.highestQualification?.docUrl
                          ?.toLowerCase()
                          .endsWith(".pdf") ? (
                          <div>
                            <FaFilePdf className="text-red-400" />
                            <p>View PDF document</p>
                          </div>
                        ) : (
                          <img
                            src={teacher?.highestQualification?.docUrl}
                            alt="Document thumbnail"
                            className="highest-qualification-img object-cover rounded-sm"
                          />
                        )}
                      </a>
                      {doc.isVerified ? (
                        <p>
                          <span>Verified</span>{" "}
                          <IoMdCheckmarkCircleOutline className="text-green-500 ml-2" />
                        </p>
                      ) : (
                        <button
                          className="px-4 py-2 mt-2 bg-red-500 text-white rounded hover:bg-red-600"
                          onClick={() => handleVerify("otherDocs", index)}
                        >
                          Verify Certificates
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Teacher Info */}
          <div className="w-full md:w-50 text-left">
            <h2 className="text-xl font-semibold text-yellow-500">Profile</h2>
            <div className="mt-4 space-y-3 text-gray-100">
              <p>
                <span className="font-semibold text-yellow-400">Email:</span>{" "}
                {teacher?.email || "N/A"}
              </p>
              <p>
                <span className="font-semibold text-yellow-400">Phone:</span>{" "}
                {teacher?.phoneNumber || "N/A"}
              </p>
              <p className="flex">
                <span className="font-semibold text-yellow-400 mr-2">
                  Aadhar:
                </span>{" "}
                {teacher?.aadhar?.number || "N/A"}{" "}
                {teacher?.aadhar?.isVerified ? (
                  <span className="text-green-500 ml-2 my-auto flex">
                    Verified
                    <IoMdCheckmarkCircleOutline className="text-green-500 ml-2 my-auto" />
                  </span>
                ) : (
                  <span
                    className="ml-2 cursor-pointer text-red-500"
                    onClick={() => handleVerify("aadhar")}
                  >
                    Mark as Verified
                  </span>
                )}
              </p>
              <p className="flex">
                <span className="font-semibold text-yellow-400 mr-2">Pan:</span>{" "}
                {teacher?.pan?.number || "N/A"}
                {teacher?.pan?.isVerified ? (
                  <span className="text-green-500 ml-2 my-auto flex">
                    Verified
                    <IoMdCheckmarkCircleOutline className="text-green-500 ml-2 my-auto" />
                  </span>
                ) : (
                  <span
                    className="ml-2 cursor-pointer text-red-500"
                    onClick={() => handleVerify("pan")}
                  >
                    Mark as Verified
                  </span>
                )}
              </p>
              <p>
                <span className="font-semibold text-yellow-400">Subjects:</span>{" "}
                {teacher?.subjects?.join(", ") || "N/A"}
              </p>
              <p>
                <span className="font-semibold text-yellow-400">
                  Experience:
                </span>{" "}
                {teacher?.yearOfExperience} years
              </p>
              <p>
                <span className="font-semibold text-yellow-400">Rating:</span>{" "}
                {teacher.averageRating
                  ? `${teacher.averageRating.toFixed(1)} / 5`
                  : "No Ratings Yet"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherDetails;
