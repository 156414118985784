import React, { useEffect, useState } from "react";
import { get } from "../../redux/ApiHelper";
import { ParentInterface } from "../../interfaces/parent.interface";
import TableComponent from "../../components/table/TableComponent";
import { Column } from "../../interfaces/table.interface";

const ParentList = () => {
  const [originalList, setOriginalList] = useState<ParentInterface[]>([]);
  const [parentList, setParentList] = useState<ParentInterface[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    get("/parent/get-all").then((res) => {
      setOriginalList(res);
      setParentList(res);
    });
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);

    if (!query.trim()) {
      setParentList(originalList);
      return;
    }

    const filteredList = originalList.filter((parent) => {
      const lowerQuery = query.toLowerCase();
      return (
        parent.name?.toLowerCase().includes(lowerQuery) ||
        parent.phoneNumber.toString().includes(lowerQuery) ||
        parent.email?.toLowerCase().includes(lowerQuery) ||
        parent.dob?.toLowerCase().includes(lowerQuery) ||
        parent.aadhar?.includes(lowerQuery) ||
        parent.pan?.toLowerCase().includes(lowerQuery) ||
        parent.address?.addressLine?.toLowerCase().includes(lowerQuery) ||
        parent.address?.city?.toLowerCase().includes(lowerQuery) ||
        parent.address?.state?.toLowerCase().includes(lowerQuery) ||
        parent.address?.pinCode?.toString().includes(lowerQuery)
      );
    });

    setParentList(filteredList);
  };

  const columns: Column[] = [
    { select: "name", header: "Name", align: "left" },
    {
      select: "phoneNumber",
      header: "Phone Number",
      render: (value, row) => `+${row.phoneCode} ${value}`,
    },
    { select: "email", header: "Email" },
    { select: "dob", header: "Date of Birth" },
    { select: "aadhar", header: "Aadhar" },
    { select: "pan", header: "PAN" },
    {
      select: "address",
      header: "Address",
      render: (value) =>
        value
          ? `${value.addressLine}, ${value.city}, ${value.state} - ${value.pinCode}`
          : "N/A",
    },
    {
      select: "students",
      header: "Students",
      render: (value) =>
        value && value.length > 0
          ? value.map((student: any) => (
              <div key={student.id}>{student.name}</div>
            ))
          : "NILL",
    },
  ];

  return (
    <div className="parent table-container">
      <div className="title">Parent List</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
          placeholder="Search parent"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <TableComponent columns={columns} data={parentList} />
    </div>
  );
};

export default ParentList;
