import { configureStore } from "@reduxjs/toolkit";
import TuitionRequestSlice from "./slices/TuitionRequestSlice";
import TeacherSlice from "./slices/TeacherSlice";
import WindowSlice from "./slices/WindowSlice";
import AdminSlice from "./slices/AdminSlice";
import CouponSlice from "./slices/CouponSlice";
import InfoSlice from "./slices/InfoSlice";
import guruCoinSlice from "./slices/GuruCoinSlice";


export const store = configureStore({
  reducer: {
    tuitionRequest: TuitionRequestSlice,
    teacher: TeacherSlice,
    window: WindowSlice,
    admin: AdminSlice,
    coupon: CouponSlice,
    info: InfoSlice,
    guruCoinPackage: guruCoinSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
