import React, { useEffect, useState } from "react";
import { CouponForm, CouponInterface } from "../../interfaces/coupon.interface";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import { createCoupon, updateCoupon } from "../../redux/slices/CouponSlice";

const CreateCouponModal = ({
  isOpen,
  closeModal,
  isEdit,
  editData,
}: {
  isOpen: boolean;
  closeModal: () => void;
  isEdit?: boolean;
  editData?: CouponInterface;
}) => {
  const dispatch: DispatchType = useDispatch();
  const [couponData, setCouponData] = useState<CouponForm>({
    couponCode: "",
    discountType: "percentage", // Default discount type
    discountValue: 0,
    expiryDate: "",
    usageLimit: 1,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState({
    discountValue: "",
    expiryDate: "",
  });

  useEffect(() => {
    if (isEdit && editData) {
      setCouponData({
        ...editData,
        expiryDate: editData.expiryDate.split("T")[0],
      });
    } else {
      setCouponData({
        couponCode: "",
        discountType: "percentage", // Default discount type
        discountValue: 0,
        expiryDate: "",
        usageLimit: 1,
      });
    }
  }, [isEdit, editData]);

  useEffect(() => {
    const { couponCode, discountType, discountValue, expiryDate, usageLimit } =
      couponData;

    const isDisabled =
      !couponCode || // Check if couponCode is empty
      !discountType || // Check if discountType is empty
      discountValue <= 0 || // Check if discountValue is invalid
      !expiryDate || // Check if expiryDate is empty
      usageLimit <= 0 || // Check if usageLimit is invalid
      (expiryDate && new Date(expiryDate) < new Date()) ||
      (discountType === "percentage" && discountValue > 99); // Check percentage limit

    setIsDisabled(isDisabled);
    // Update error messages
    const errors = {
      discountValue:
        discountType === "percentage" && discountValue > 99
          ? "Percentage discount cannot exceed 100%"
          : "",
      expiryDate:
        expiryDate && new Date(expiryDate) < new Date()
          ? "Expiry date cannot be in the past"
          : "",
    };

    setErrors(errors);
  }, [couponData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setCouponData({ ...couponData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (isEdit && editData) {
      dispatch(updateCoupon({ id: editData?._id, data: couponData })).then(
        (res) => {
          if (res) {
            closeModal(); // Close the modal after successful update
          }
        }
      );
    } else {
      dispatch(createCoupon(couponData))
        .unwrap()
        .then((res) => {
          if (res) {
            closeModal(); // Close the modal after successful creation
          }
        });
    }
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg mx-4 p-6 rounded-lg shadow-lg max-h-screen overflow-auto">
        {/* Header */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            {isEdit && editData ? "Update" : "Create New"} Coupon
          </h2>
        </div>

        {/* Input Fields */}
        <div className="space-y-4">
          <div>
            <label
              htmlFor="couponCode"
              className="block text-left text-gray-700"
            >
              Coupon Code
            </label>
            <input
              type="text"
              name="couponCode"
              value={couponData.couponCode}
              onChange={handleChange}
              placeholder="Coupon Code"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label
              htmlFor="discountType"
              className="block text-left text-gray-700"
            >
              Discount Type
            </label>
            <select
              name="discountType"
              value={couponData.discountType}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="percentage">Percentage</option>
              <option value="amount">Flat</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="discountValue"
              className="block text-left text-gray-700"
            >
              Discount Value
            </label>
            <input
              type="number"
              name="discountValue"
              value={couponData.discountValue}
              onChange={handleChange}
              placeholder="Discount Value"
              className={`w-full px-4 py-2 border ${
                errors.discountValue ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.discountValue && (
              <span className="text-red-500 text-sm text-left pl-1">
                {errors.discountValue}
              </span>
            )}
          </div>

          <div>
            <label
              htmlFor="expiryDate"
              className="block text-left text-gray-700"
            >
              Expiry Date
            </label>
            <input
              type="date"
              name="expiryDate"
              value={couponData.expiryDate}
              onChange={handleChange}
              min={new Date().toISOString().split("T")[0]} // Set min date to today
              className={`w-full px-4 py-2 border ${
                errors.expiryDate ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.expiryDate && (
              <span className="text-red-500 text-sm text-left pl-1">
                {errors.expiryDate}
              </span>
            )}
          </div>

          <div>
            <label
              htmlFor="usageLimit"
              className="block text-left text-gray-700"
            >
              Usage Limit
            </label>
            <input
              type="number"
              name="usageLimit"
              value={couponData.usageLimit}
              onChange={handleChange}
              placeholder="Usage Limit"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-yellow-600 rounded-md hover:bg-yellow-700 transition disabled:opacity-50"
            disabled={isDisabled}
          >
            {isEdit && editData ? "Update" : "Create"} Coupon
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCouponModal;
