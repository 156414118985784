import React, { useEffect, useState } from "react";
import TableComponent from "../../components/table/TableComponent";
import { AdminInterface } from "../../interfaces/admin.interface";
import CreateAdminModal from "./CreateAdminModal";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import { getAdmins, updateAdmin } from "../../redux/slices/AdminSlice";
import { FormControlLabel, Switch } from "@mui/material";
import { successToast } from "../../utils/toast.util";
import { Column } from "../../interfaces/table.interface";

const AdminList = () => {
  const dispatch: DispatchType = useDispatch();
  const { adminList }: { adminList: AdminInterface[] } = useSelector(
    (state: any) => state.admin
  );
  const [filteredAdminList, setFilteredAdminList] = useState<AdminInterface[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAdmins());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredAdminList(adminList);
  }, [adminList]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = adminList.filter((admin) => {
      return (
        admin.name?.toLowerCase().includes(lowerQuery) ||
        admin.phoneNumber?.toString().toLowerCase().includes(lowerQuery) ||
        admin.email?.toLowerCase().includes(lowerQuery)
      );
    });

    setFilteredAdminList(filteredList);
  };

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns: Column[] = [
    { select: "name", header: "Name", align: "left" },
    { select: "phoneNumber", header: "Phone Number" },
    { select: "email", header: "Email" },
    {
      select: "isActive",
      header: "Status",
      render: (value: boolean, rowData: AdminInterface) => (
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={value}
              onChange={(e) =>
                dispatch(
                  updateAdmin({
                    id: rowData?._id,
                    data: { isActive: e.target.checked },
                  })
                )
                  .unwrap()
                  .then(() => {
                    successToast("Status updated successfully");
                  })
              }
            />
          }
          label="Active"
          labelPlacement="end" // 'start', 'top', 'bottom' are also valid options
        />
      ),
    },
  ];

  return (
    <div className="admin table-container">
      <div className="title">Admin List</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search admin"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredAdminList} />

      {/* Floating Action Button */}
      <div
        onClick={openModal}
        className="fixed top-8 right-8 bg-yellow-500 text-white p-2 rounded-full cursor-pointer shadow-lg hover:bg-yellow-600"
      >
        <MdAdd />
      </div>

      {/* Create Admin Modal */}
      <CreateAdminModal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default AdminList;
