import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../redux/ApiHelper";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import { useDispatch } from "react-redux";
import {
  FaChalkboardTeacher,
  FaUserTie,
  FaUserGraduate,
  FaSchool,
  FaHourglassHalf,
} from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";

interface DashboardInfo {
  totalAdminCount: number;
  totalTeacherCount: number;
  totalParentCount: number;
  totalStudentCount: number;
  totalClassCount: number;
  totalPendingTuitionRequestCount: number;
}

const Dashboard = () => {
  const [data, setData] = useState<DashboardInfo | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDashboardInfo = async () => {
      dispatch(startLoading());
      await get("/information/get-info")
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          console.error("Error fetching dashboard data:", error);
        })
        .finally(() => {
          dispatch(stopLoading());
        });
    };

    fetchDashboardInfo();
    // eslint-disable-next-line
  }, []);

  const cards = [
    {
      title: "Total Admins",
      count: data?.totalAdminCount || 0,
      icon: <RiAdminFill size={40} className="text-red-400" />,
      onClick: () => navigate("/admin"),
    },
    {
      title: "Total Teachers",
      count: data?.totalTeacherCount || 0,
      icon: <FaChalkboardTeacher size={40} className="text-blue-500" />,
      onClick: () => navigate("/teacher"),
    },
    {
      title: "Total Parents",
      count: data?.totalParentCount || 0,
      icon: <FaUserTie size={40} className="text-green-500" />,
      onClick: () => navigate("/parent"),
    },
    {
      title: "Total Students",
      count: data?.totalStudentCount || 0,
      icon: <FaUserGraduate size={40} className="text-purple-500" />,
      onClick: () => navigate("/student"),
    },
    {
      title: "Total Classes",
      count: data?.totalClassCount || 0,
      icon: <FaSchool size={40} className="text-yellow-500" />,
      onClick: () => navigate("/tuition-classes"),
    },
    {
      title: "Pending Tuition Requests",
      count: data?.totalPendingTuitionRequestCount || 0,
      icon: <FaHourglassHalf size={40} className="text-red-500" />,
      onClick: () => navigate("/tuition-requests"),
    },
  ];

  return (
    <div className="flex justify-center p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-screen-lg">
        {cards.map((card, index) => (
          <div
            key={index}
            onClick={card.onClick}
            className="p-6 bg-gradient-to-r from-blue-50 to-white shadow-lg rounded-lg cursor-pointer hover:shadow-xl transition-transform transform hover:scale-105 text-center"
          >
            <div className="flex justify-center mb-4">{card.icon}</div>
            <h3 className="text-lg font-semibold text-gray-700">
              {card.title}
            </h3>
            <p className="text-3xl font-bold text-gray-800 mt-2">
              {card.count}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
