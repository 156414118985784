// src/components/Layout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Loader from "./components/loader/Loader";
import { useSelector } from "react-redux";

const Layout: React.FC = () => {
  const { loader } = useSelector((state: any) => state.window);

  return (
    <div style={{ display: "flex" }} className="layout">
      {loader && <Loader />}
      <Sidebar />
      <main
        style={{ flex: 1, padding: "20px" }}
        className="main-content h-screen flex flex-col"
      >
        <Outlet /> {/* This is where the page content will be rendered */}
      </main>
    </div>
  );
};

export default Layout;
