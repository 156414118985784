import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { TeacherInterface } from "../../../interfaces/teacher.interface";
import { updateTeacher } from "../../../redux/slices/TeacherSlice";
import { DispatchType } from "../../../interfaces/redux.interface";
import { successToast } from "../../../utils/toast.util";

const TeacherStatusHandler = ({ teacher }: { teacher: TeacherInterface }) => {
  const dispatch: DispatchType = useDispatch();
  const [isActive, setIsActive] = useState(teacher.isActive);
  const [openDialog, setOpenDialog] = useState(false);
  const [deactivationReason, setDeactivationReason] = useState<string>("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleStatusChange = (checked: boolean) => {
    if (!checked) {
      // If deactivating, open the dialog
      setOpenDialog(true);
    } else {
      // Activating the account
      updateTeacherStatus(true, "");
    }
  };

  const updateTeacherStatus = (status: boolean, reason: string) => {
    dispatch(
      updateTeacher({
        id: teacher._id,
        data: { isActive: status, deactivationReason: reason },
      })
    )
      .unwrap()
      .then(() => {
        successToast(
          status
            ? "Account activated successfully"
            : "Account deactivated successfully"
        );
        setIsActive(status);
        setDeactivationReason("");
        setOpenDialog(false);
      });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDeactivationReason("");
  };

  const handleReasonChange = (reason: string) => {
    setDeactivationReason(reason);
    setIsSubmitDisabled(reason.trim().length === 0); // Enable submit only if reason is provided
  };

  const handleDeactivationSubmit = () => {
    updateTeacherStatus(false, deactivationReason);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            size="small"
            checked={isActive}
            onChange={(e) => handleStatusChange(e.target.checked)}
          />
        }
        label={isActive ? "Active" : "Inactive"}
      />

      {/* Deactivation Reason Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Deactivate Account</DialogTitle>
        <div className="py-2 px-6">
          <textarea
            className="w-full border rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            rows={4}
            placeholder="Reason for deactivation"
            value={deactivationReason}
            onChange={(e) => handleReasonChange(e.target.value)}
          ></textarea>
        </div>
        <DialogActions>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={handleDialogClose}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 text-white rounded ${
              isSubmitDisabled
                ? "bg-blue-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            onClick={handleDeactivationSubmit}
            disabled={isSubmitDisabled}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TeacherStatusHandler;
