import React, { useEffect, useState } from "react";
import { get, post } from "../../../redux/ApiHelper";
import { Column } from "../../../interfaces/table.interface";
import TableComponent from "../../../components/table/TableComponent";
import { formatDateTime } from "../../../utils/date&time.util";
import { PaymentInterface } from "../../../interfaces/payment.interface";
import { IconButton, Tooltip } from "@mui/material";
import { FaCopy } from "react-icons/fa";
import { errorToast, successToast } from "../../../utils/toast.util";

const PaymentsPage = () => {
  const [paymentsList, setPaymentsList] = useState<PaymentInterface[]>([]);
  const [filteredPaymentsList, setFilteredPaymentsList] = useState<
    PaymentInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [verifyingMap, setVerifyingMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    get("/admin/payment/all").then((res) => {
      setPaymentsList(res);
      setFilteredPaymentsList(res);
    });
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = paymentsList?.filter((payment) => {
      const createdByName = payment.createdBy?.name?.toLowerCase() || "";
      const role = payment.role?.toLowerCase() || "";
      const transactionId = payment.transactionId?.toLowerCase() || "";
      const externalTransactionId =
        payment.externalTransactionId?.toLowerCase() || "";
      const paymentDate = formatDateTime(payment?.createdAt);

      return (
        payment._id.includes(lowerQuery) ||
        payment.amount.toString().includes(lowerQuery) ||
        payment.currency.toLowerCase().includes(lowerQuery) ||
        role.includes(lowerQuery) ||
        createdByName.includes(lowerQuery) ||
        transactionId.includes(lowerQuery) ||
        paymentDate.toLowerCase().includes(lowerQuery) ||
        externalTransactionId.includes(lowerQuery)
      );
    });

    setFilteredPaymentsList(filteredList);
  };

  const columns: Column[] = [
    { select: "_id", header: "Payment ID", align: "left" },
    { select: "role", header: "Role" },
    {
      header: "Created By",
      render: (_value: any, row: PaymentInterface) =>
        row?.createdBy?.name || "N/A",
    },
    { select: "amount", header: "Amount" },
    { select: "currency", header: "Currency" },
    {
      header: "Status",
      render: (_value: any, row: PaymentInterface) => {
        const isVerifying = verifyingMap[row._id];

        const handleVerify = async () => {
          setVerifyingMap((prev) => ({ ...prev, [row._id]: true }));
          post(`/admin/payment/order-validate/${row._id}`, {
            itemType: "teacherSubscription",
          })
            .then((response) => {
              const { newPaymentData, message } = response;

              const updatedList = paymentsList.map((payment) =>
                payment._id === row._id
                  ? { ...payment, ...newPaymentData }
                  : payment
              );
              setPaymentsList(updatedList);
              setFilteredPaymentsList(updatedList);
              successToast(message || "Payment successfully updated!");
            })
            .catch(() =>
              errorToast("Failed to verify payment. Please try again.")
            )
            .finally(() =>
              setVerifyingMap((prev) => ({ ...prev, [row._id]: false }))
            );
        };

        const isCompleted = row?.status === "Completed";

        return (
          <div className="flex items-center">
            <span
              className={`status-badge ${
                isCompleted
                  ? "bg-green-500"
                  : row?.status === "Pending"
                  ? "bg-yellow-500"
                  : "bg-red-500"
              } text-white px-2 py-1 rounded`}
            >
              {row?.status}
            </span>
            {!isCompleted && (
              <div className="ml-2">
                <Tooltip
                  title="Click to verify the payment if it seems stuck. This will validate the payment status."
                  placement="top"
                >
                  <button
                    className={`text-white px-3 py-1 rounded ${
                      isVerifying
                        ? "bg-blue-300 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                    onClick={handleVerify}
                    disabled={isVerifying}
                    aria-label="Verify Payment"
                  >
                    {isVerifying ? "Verifying..." : "Verify"}
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      header: "Transaction ID",
      render: (_value: any, row: PaymentInterface) =>
        row?.transactionId || "N/A",
    },
    {
      header: "External Transaction ID",
      render: (_value: any, row: PaymentInterface) => {
        const transactionId = row?.externalTransactionId || "N/A";

        if (transactionId === "N/A") {
          return transactionId;
        }

        const handleCopy = () => {
          navigator.clipboard.writeText(transactionId);
          successToast("Transaction ID copied to clipboard!");
        };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 8 }}>
              {transactionId.slice(0, 10)}...
            </span>
            <Tooltip title="Copy Transaction ID">
              <IconButton
                onClick={handleCopy}
                size="small"
                aria-label="Copy Transaction ID"
              >
                <FaCopy />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      header: "Date & Time",
      render: (_value: any, row: PaymentInterface) =>
        formatDateTime(row?.createdAt),
    },
  ];

  return (
    <div className="paymentsPage table-container">
      <div className="title">Payments</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search payments"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredPaymentsList} />
    </div>
  );
};

export default PaymentsPage;
