// src/App.tsx
import React, { useEffect } from "react";
import "./App.css";
import "./global.scss";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";

import { useNavigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Request from "./pages/request/Request";
import RequestDetails from "./pages/request/teacher/RequestDetails";
import TuitionClasses from "./pages/tuition-classes/TuitionClasses";
import TeacherList from "./pages/teacher/TeacherList";
import StudentList from "./pages/student/StudentList";
import ParentList from "./pages/parent/ParentList";
import { ToastProvider } from "./components/toasts/ToastContext";
import ToastInitializer from "./components/toasts/ToastInitializer";
import AdminList from "./pages/admin/AdminList";
import TeacherDetails from "./pages/teacher/TeacherDetails";
import CouponList from "./pages/coupon/CouponList";
import InfoSettings from "./pages/info/Info";
import GuruCoin from "./pages/guru-coin/GuruCoin";
import GuruCoinTransaction from "./pages/transactions/guru-coin-transactions/GuruCoinTransaction";
import PaymentsPage from "./pages/transactions/payments/Payments";
import TuitionJobPostsPage from "./pages/tuition/job-posts/TuitionJobPosts";
import TermsConditionsForTeachers from "./pages/page-contents/terms-conditions/TermsConditionsForTeachers";
import TermsConditionsForParents from "./pages/page-contents/terms-conditions/TermsConditionsForParents";

const useAuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/login");
    } else {
      // You can add token validation or refresh logic here if necessary
    }
  }, [navigate]); // Trigger this effect whenever the component mounts
};

const App: React.FC = () => {
  useAuthCheck(); // Custom hook to check authentication and redirect if necessary
  return (
    // <Router>
    <div className="App">
      <ToastProvider>
        <ToastInitializer />
        <Routes>
          {/* All routes will be wrapped with Layout */}
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/teacher" element={<TeacherList />} />
            <Route path="/teacher/:id" element={<TeacherDetails />} />
            <Route path="/student" element={<StudentList />} />
            <Route path="/parent" element={<ParentList />} />
            <Route path="/tuition-requests" element={<Request />} />
            <Route path="/request/:id" element={<RequestDetails />} />
            <Route path="/tuition-classes" element={<TuitionClasses />} />
            <Route path="/admin" element={<AdminList />} />
            <Route path="/coupon" element={<CouponList />} />
            <Route path="/info-settings" element={<InfoSettings />} />
            <Route path="/guru-coin" element={<GuruCoin />} />
            <Route
              path="/guru-coin-transaction"
              element={<GuruCoinTransaction />}
            />
            <Route path="/payments" element={<PaymentsPage />} />
            <Route
              path="/tuition-job-posts"
              element={<TuitionJobPostsPage />}
            />
            <Route
              path="/terms-conditions/teachers"
              element={<TermsConditionsForTeachers />}
            />
            <Route
              path="/terms-conditions/parents"
              element={<TermsConditionsForParents />}
            />
          </Route>

          {/* Routes without layout */}
          <Route path="/login" element={<Login />} />

          {/* Catch-all Route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ToastProvider>
    </div>
    // </Router>
  );
};

export default App;
