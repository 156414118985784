// src/components/toasts/ToastContext.tsx
import React, { createContext, useContext, useState } from "react";
import {
  FiCheckCircle,
  FiXCircle,
  FiAlertCircle,
  FiInfo,
} from "react-icons/fi";

type ToastContextType = {
  showToast: (
    message: string,
    type: "success" | "error" | "warning" | "info"
  ) => void;
};

// Define ToastProvider with correct typing for children
interface ToastProviderProps {
  children: React.ReactNode; // This allows any React element to be passed as children
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<{ message: string; type: string }[]>([]);

  const showToast = (
    message: string,
    type: "success" | "error" | "warning" | "info"
  ) => {
    // Clear the previous toast and show the new one
    setToasts([{ message, type }]);
    setTimeout(() => {
      setToasts([]); // Clear the toast after 3 seconds
    }, 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div className="fixed top-0 right-0 p-4 space-y-2 z-50">
        {toasts.map((toast, index) => (
          <div
            key={index}
            className={`flex items-center p-4 rounded shadow-lg transition-all duration-300 bg-white border-l-4 min-w-[250px] ${
              toast.type === "success"
                ? "border-green-500"
                : toast.type === "error"
                ? "border-red-500"
                : toast.type === "warning"
                ? "border-yellow-500"
                : "border-blue-500"
            }`}
          >
            <div className="mr-3">
              {toast.type === "success" && (
                <FiCheckCircle className="text-green-500 text-2xl" />
              )}
              {toast.type === "error" && (
                <FiXCircle className="text-red-500 text-2xl" />
              )}
              {toast.type === "warning" && (
                <FiAlertCircle className="text-yellow-500 text-2xl" />
              )}
              {toast.type === "info" && (
                <FiInfo className="text-blue-500 text-2xl" />
              )}
            </div>
            <span className="text-gray-700">{toast.message}</span>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
