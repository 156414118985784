import React, { useEffect, useState } from "react";
import TableComponent from "../../components/table/TableComponent";
import { MdAdd, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import { FormControlLabel, Switch } from "@mui/material";
import { successToast } from "../../utils/toast.util";
import { Column } from "../../interfaces/table.interface";
import { CouponInterface } from "../../interfaces/coupon.interface";
import { getCoupons, updateCoupon } from "../../redux/slices/CouponSlice";
import CreateCouponModal from "./CreateCouponModal";
import { RootState } from "../../redux/store";

const CouponList = () => {
  const dispatch: DispatchType = useDispatch();
  const { couponList }: { couponList: CouponInterface[] } = useSelector(
    (state: RootState) => state.coupon
  );
  const [filteredCouponList, setFilteredCouponList] = useState<
    CouponInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<CouponInterface>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCoupons());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredCouponList(couponList);
  }, [couponList]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = couponList.filter((coupon) => {
      return (
        coupon._id?.toLowerCase().includes(lowerQuery) || // Search by ID
        coupon.couponCode?.toLowerCase().includes(lowerQuery) || // Search by Coupon Code
        coupon.discountType?.toLowerCase().includes(lowerQuery) || // Search by Discount Type
        (coupon.discountValue?.toString().toLowerCase() || "").includes(
          lowerQuery
        ) || // Search by Discount Value
        coupon.expiryDate?.toLowerCase().includes(lowerQuery) || // Search by Expiry Date
        (coupon.usageLimit?.toString().toLowerCase() || "").includes(
          lowerQuery
        ) || // Search by Usage Limit
        (coupon.remainingUses?.toString().toLowerCase() || "").includes(
          lowerQuery
        ) || // Search by Remaining Uses
        (coupon.isActive ? "active" : "inactive").includes(lowerQuery) // Search by Status
      );
    });

    setFilteredCouponList(filteredList);
  };

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null);
    setIsEdit(false);
  };

  const columns: Column[] = [
    { select: "couponCode", header: "Coupon Code", align: "left" },
    { select: "discountValue", header: "Discount Value" },
    { select: "discountType", header: "Discount Type" },
    {
      select: "expiryDate",
      header: "Expiry Date",
      render: (value: boolean, rowData: CouponInterface) =>
        new Date(rowData?.expiryDate).toLocaleDateString("en-IN"),
    },
    { select: "usageLimit", header: "Usage Limit" },
    { select: "remainingUses", header: "Remaining Usage" },
    {
      select: "isActive",
      header: "Status",
      render: (value: boolean, rowData: CouponInterface) => (
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={value}
              onChange={(e) =>
                dispatch(
                  updateCoupon({
                    id: rowData._id,
                    data: { isActive: e.target.checked },
                  })
                )
                  .unwrap()
                  .then(() => {
                    successToast("Status updated successfully");
                  })
              }
            />
          }
          label="Active"
          labelPlacement="end"
        />
      ),
    },
    {
      header: "Edit",
      render: (value: boolean, rowData: CouponInterface) => (
        <div
          className="rounded-full bg-slate-200 p-2 text-yellow-500 cursor-pointer"
          onClick={() => {
            setEditData(rowData);
            setIsEdit(true);
            setIsModalOpen(true);
          }}
        >
          <MdEdit className="m-auto" />
        </div>
      ),
    },
  ];

  return (
    <div className="coupon table-container">
      <div className="title">Coupon List</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search coupon by name, code, or discount value"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredCouponList} />

      {/* Floating Action Button */}
      <div
        onClick={openModal}
        className="fixed top-8 right-8 bg-yellow-500 text-white p-2 rounded-full cursor-pointer shadow-lg hover:bg-yellow-600"
      >
        <MdAdd />
      </div>

      {/* Create Coupon Modal */}
      <CreateCouponModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        isEdit={isEdit}
        editData={editData}
      />
    </div>
  );
};

export default CouponList;
