import React, { useState, useEffect } from "react";
import { TuitionRequestInterface } from "../../../interfaces/tuitionRequest.interface";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import "../Request.scss";
import { get } from "../../../redux/ApiHelper";
import ApprovedRequestDetails from "./components/ApprovedRequestDetails";
import RequestForm from "./components/RequestForm";
import { useParams } from "react-router-dom";

const RequestDetails = () => {
  const { id } = useParams();

  const [requestDetails, setRequestDetails] =
    useState<TuitionRequestInterface>();

  useEffect(() => {
    if (id) {
      get(`/tuition/tuition-request-details/${id}`).then((response) => {
        setRequestDetails(response);
      });
    }
  }, [id]);

  if (!id) {
    return <div>No ID provided. Please select a request.</div>;
  }

  if (!requestDetails) {
    return <div>No request found for the given ID.</div>;
  }

  return (
    <div className="flex-grow flex flex-col bg-primary-dark p-8 flex justify-center items-center overflow-hidden">
      <Paper className="p-8 w-full  rounded-lg shadow-lg flex-grow overflow-auto flex flex-col">
        <h2 className="text-2xl font-semibold text-primary-dark mb-6">
          Request Details
        </h2>

        <div className="space-y-4 my-4">
          <div className="flex justify-between gap-6">
            <div className="text-left space-y-2">
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">Name:</span>{" "}
                <span className="text-black">
                  {requestDetails?.student?.name || "N/A"}
                </span>
              </p>
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">Email:</span>{" "}
                <span className="text-black">
                  {requestDetails?.parent?.email || "N/A"}
                </span>
              </p>
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">Phone:</span>{" "}
                <span className="text-black">
                  {`${requestDetails?.parent?.phoneCode || ""} ${
                    requestDetails?.parent?.phoneNumber || ""
                  }`}
                </span>
              </p>
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">Address:</span>{" "}
                <span className="text-black">
                  {`${requestDetails?.parent?.address?.addressLine || ""}, ${
                    requestDetails?.parent?.address?.city || ""
                  }, ${requestDetails?.parent?.address?.state || ""} - ${
                    requestDetails?.parent?.address?.pinCode || ""
                  }`}
                </span>
              </p>
            </div>
            <div className="text-left space-y-2">
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">
                  Required Subject(s):
                </span>{" "}
                <span className="text-black">
                  {requestDetails?.preferredSubjects
                    ? requestDetails?.preferredSubjects?.join(", ")
                    : "N/A"}
                </span>
              </p>
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">
                  Preferred Tuition Hour:
                </span>{" "}
                <span className="text-black">
                  {requestDetails?.preferredHours || "N/A"}
                </span>
              </p>
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">
                  Preferred Teacher Gender:
                </span>{" "}
                <span className="text-black">
                  {requestDetails?.preferredTeacherGender || "Any"}
                </span>
              </p>
              <p className="text-gray-700">
                <span className="text-gray-500 font-semibold">
                  Preferred Teacher Experience:
                </span>{" "}
                <span className="text-black">
                  {requestDetails?.requiredTeacherExperience || "Any"}
                </span>
              </p>
            </div>
          </div>

          {/* Display Student's Preferred Schedule */}
          <div className="space-y-2">
            <h3 className="font-semibold text-lg">
              Student's Preferred Schedule
            </h3>
            <div className="p-4 border border-yellow-400 rounded-lg shadow-sm">
              <Table className="request-detatls-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestDetails?.preferredSchedule?.map(
                    ({ day, from, to }) => (
                      <TableRow key={day}>
                        <TableCell>{day}</TableCell>
                        <TableCell>{from}</TableCell>
                        <TableCell>{to}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          {requestDetails?.adminStatus === "approved" ? (
            <ApprovedRequestDetails tuitionRequest={requestDetails} />
          ) : (
            // <div className="space-y-4">
            //   {/* Teacher Selection and Subjects (in one line) */}
            //   <div className="flex gap-6">
            //     {/* Teacher Selection */}
            //     <FormControl className="sm:w-full md:w-1/2">
            //       <Autocomplete
            //         options={filteredTeachers || []}
            //         getOptionLabel={(teacher) => teacher.name || ""}
            //         value={selectedTeacher}
            //         onChange={(event, newValue) => setSelectedTeacher(newValue)}
            //         isOptionEqualToValue={(option, value) =>
            //           option._id === value._id
            //         }
            //         filterOptions={(options, { inputValue }) =>
            //           options.filter((teacher) =>
            //             teacher.name
            //               .toLowerCase()
            //               .includes(inputValue.toLowerCase())
            //           )
            //         }
            //         renderOption={(props, teacher) => (
            //           <li {...props} key={teacher._id}>
            //             <div>
            //               <strong>{teacher.name}</strong>
            //               <div className="text-sm text-gray-500">
            //                 {teacher.phoneCode} {teacher.phoneNumber} -{" "}
            //                 {teacher.address
            //                   ? `${teacher.address.city}, ${teacher.address.state}`
            //                   : "Address not available"}
            //               </div>
            //             </div>
            //           </li>
            //         )}
            //         renderInput={(params) => (
            //           <TextField
            //             {...params}
            //             label="Assign Teacher"
            //             placeholder="Search by Name, Address, or Subject"
            //           />
            //         )}
            //       />
            //     </FormControl>

            //     {/* Subjects Selection */}
            //     <FormControl className="sm:w-full md:w-1/2">
            //       <InputLabel>Subjects</InputLabel>
            //       <Select
            //         multiple
            //         value={selectedSubjects}
            //         onChange={handleSubjectChange}
            //         label="Subjects"
            //       >
            //         {requestDetails?.preferredSubjects
            //           ? requestDetails?.preferredSubjects?.map((subject) => (
            //               <MenuItem key={subject} value={subject}>
            //                 {subject}
            //               </MenuItem>
            //             ))
            //           : filteredTeachers?.[0]?.subjects?.map((subject) => (
            //               <MenuItem key={subject} value={subject}>
            //                 {subject}
            //               </MenuItem>
            //             ))}
            //       </Select>
            //     </FormControl>
            //   </div>

            //   {/* Input Fields (Total Classes and Guru Coin Charges) */}
            //   <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            //     <div className="space-y-2">
            //       <label
            //         htmlFor="total-classes"
            //         className="block text-sm font-medium text-gray-700 text-left"
            //       >
            //         Total Number of Classes
            //       </label>
            //       <input
            //         id="total-classes"
            //         type="number"
            //         className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            //         value={totalNumberOfClasses}
            //         onChange={(e) =>
            //           setTotalNumberOfClasses(parseInt(e.target.value))
            //         }
            //       />
            //     </div>

            //     <div className="space-y-2">
            //       <label
            //         htmlFor="guru-coin-charges"
            //         className="block text-sm font-medium text-gray-700 text-left"
            //       >
            //         Guru Coin Charges
            //       </label>
            //       <input
            //         id="guru-coin-charges"
            //         type="number"
            //         className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            //         value={guruCoinCharge}
            //         onChange={(e) =>
            //           setGuruCoinCharge(parseInt(e.target.value))
            //         }
            //       />
            //     </div>
            //   </div>
            // </div>
            <RequestForm requestDetails={requestDetails} />
          )}
        </div>
        {/* Action Buttons
        {requestDetails?.adminStatus === "pending" && (
          <div className="flex flex-col space-y-4 mt-auto">
            <button
              onClick={() => handleSubmit("rejected")}
              className="bg-red-500 text-white py-2 px-6 rounded-lg hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-300"
            >
              Reject Request
            </button>
            <button
              onClick={() => handleSubmit("approved")}
              className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-300 mt-auto"
            >
              Approve Request
            </button>
          </div>
        )} */}
      </Paper>
    </div>
  );
};

export default RequestDetails;
