// src/pages/NotFound.tsx
import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import "./notFound.scss";

const NotFound: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button onClick={handleGoBack}>Go Back</button> {/* Button to go back */}
    </div>
  );
};

export default NotFound;
