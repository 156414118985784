import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../../utils/toast.util";
import { DispatchType } from "../../../../interfaces/redux.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/slices/WindowSlice";
import { changeRequestStatus } from "../../../../redux/slices/TuitionRequestSlice";
import { useNavigate } from "react-router-dom";
import { TuitionRequestInterface } from "../../../../interfaces/tuitionRequest.interface";
import { TeacherInterface } from "../../../../interfaces/teacher.interface";
import { RootState } from "../../../../redux/store";
import { getInfo } from "../../../../redux/slices/InfoSlice";

const RequestForm = ({
  requestDetails,
}: {
  requestDetails: TuitionRequestInterface;
}) => {
  const dispatch: DispatchType = useDispatch();
  const navigate = useNavigate();

  const { allTeachers }: { allTeachers: TeacherInterface[] } = useSelector(
    (state: RootState) => state.teacher
  );
  const { info } = useSelector((state: RootState) => state.info);

  const [selectedTeacher, setSelectedTeacher] =
    useState<TeacherInterface | null>(null);
  const [filteredTeachers, setFilteredTeachers] =
    useState<TeacherInterface[]>();

  const [totalNumberOfClasses, setTotalNumberOfClasses] = useState<number>();
  const [guruCoinCharge, setGuruCoinCharge] = useState<number>();
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);

  useEffect(() => {
    if (requestDetails) {
      if (info) {
        if (requestDetails?.preferredTeacher) {
          setSelectedTeacher(requestDetails?.preferredTeacher);
          setFilteredTeachers([requestDetails?.preferredTeacher]);
        } else {
          setFilteredTeachers(allTeachers);
        }

        if (requestDetails?.isFirst) {
          setTotalNumberOfClasses(info?.firstTuitionSession?.numberOfClasses);
          setGuruCoinCharge(info?.firstTuitionSession?.guruCoinCharge);
        }
      } else {
        dispatch(getInfo());
      }
    }
    // eslint-disable-next-line
  }, [allTeachers, requestDetails, info]);

  const handleSubmit = async (status: "approved" | "rejected") => {
    if (!selectedTeacher) {
      warningToast("Please select a teacher.");
      return;
    }
    if (selectedSubjects?.length === 0) {
      warningToast("Please select at least one subject.");
      return;
    }
    if (!totalNumberOfClasses) {
      warningToast("Please enter total number of classes you want to assign.");
      return;
    }
    if (!guruCoinCharge) {
      warningToast("Please enter guru coin charge for each class.");
      return;
    }
    dispatch(startLoading());
    // Ensure that only the days with valid 'from' and 'to' values are sent to the backend
    await dispatch(
      changeRequestStatus({
        id: requestDetails?._id,
        status,
        teacher: selectedTeacher?._id,
        totalNumberOfClasses,
        subjects: selectedSubjects,
        guruCoinCharge,
      })
    )
      .unwrap()
      .then((res: any) => {
        successToast("successfully tuition period created ");
        navigate("/tuition-requests");
      })
      .catch((err) => {
        errorToast("something went wrong");
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  const handleSubjectChange = (e: SelectChangeEvent<unknown>) => {
    // Ensure the value is always an array of strings (string[])
    const value = Array.isArray(e.target.value)
      ? e.target.value
      : [e.target.value];
    setSelectedSubjects(value);
  };

  return (
    <div className="space-y-4 mt-4">
      {/* Teacher Selection and Subjects (in one line) */}
      <div className="flex gap-6">
        {/* Teacher Selection */}
        <FormControl className="sm:w-full md:w-1/2">
          <Autocomplete
            options={filteredTeachers || []}
            getOptionLabel={(teacher) => teacher.name || ""}
            value={selectedTeacher}
            onChange={(event, newValue) => setSelectedTeacher(newValue)}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            filterOptions={(options, { inputValue }) =>
              options.filter((teacher) =>
                teacher.name.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            renderOption={(props, teacher) => (
              <li {...props} key={teacher._id}>
                <div>
                  <strong>{teacher.name}</strong>
                  <div className="text-sm text-gray-500">
                    {teacher.phoneCode} {teacher.phoneNumber} -{" "}
                    {teacher.address
                      ? `${teacher.address.city}, ${teacher.address.state}`
                      : "Address not available"}
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign Teacher"
                placeholder="Search by Name, Address, or Subject"
              />
            )}
          />
        </FormControl>

        {/* Subjects Selection */}
        <FormControl className="sm:w-full md:w-1/2">
          <InputLabel>Subjects</InputLabel>
          <Select
            multiple
            value={selectedSubjects}
            onChange={handleSubjectChange}
            label="Subjects"
          >
            {requestDetails?.preferredSubjects
              ? requestDetails?.preferredSubjects?.map((subject) => (
                  <MenuItem key={subject} value={subject}>
                    {subject}
                  </MenuItem>
                ))
              : filteredTeachers?.[0]?.subjects?.map((subject) => (
                  <MenuItem key={subject} value={subject}>
                    {subject}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>

      {/* Input Fields (Total Classes and Guru Coin Charges) */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label
            htmlFor="total-classes"
            className="block text-sm font-medium text-gray-700 text-left"
          >
            Total Number of Classes
          </label>
          <input
            id="total-classes"
            type="number"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            value={totalNumberOfClasses}
            onChange={(e) => setTotalNumberOfClasses(parseInt(e.target.value))}
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="guru-coin-charges"
            className="block text-sm font-medium text-gray-700 text-left"
          >
            Guru Coin Charges
          </label>
          <input
            id="guru-coin-charges"
            type="number"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            value={guruCoinCharge}
            onChange={(e) => setGuruCoinCharge(parseInt(e.target.value))}
          />
        </div>
      </div>

      {/* Action Buttons */}
      {requestDetails?.adminStatus === "pending" && (
        <div className="flex flex-col space-y-4 mt-auto">
          <button
            onClick={() => handleSubmit("rejected")}
            className="bg-red-500 text-white py-2 px-6 rounded-lg hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-300"
          >
            Reject Request
          </button>
          <button
            onClick={() => handleSubmit("approved")}
            className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-300 mt-auto"
          >
            Approve Request
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestForm;
