import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import "./TableComponent.scss";
import { TableComponentProps } from "../../interfaces/table.interface";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "var(--color-primary)",
    padding: 4,
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 1, // Ensure it is on top of the body
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 4,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableComponent: React.FC<TableComponentProps> = ({ columns, data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  return (
    <div className="table-component-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <StyledTableCell
                  key={index}
                  style={{
                    textAlign: column?.align || "center",
                    padding: "4px 16px",
                  }}
                >
                  {column.header}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={columns?.length}
                  style={{ textAlign: "center" }}
                >
                  No Data Available
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    {columns?.map((column, colIndex) => (
                      <StyledTableCell
                        key={colIndex}
                        style={{
                          textAlign: column?.align || "center",
                          padding: "8px 16px",
                        }}
                      >
                        {column?.render
                          ? column?.render(row[column.select], row)
                          : row[column.select]}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 25, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => {
          const adjustedTo = Math.min(to, count); // Ensure 'to' does not exceed count
          return `${from}–${adjustedTo} of ${count}`;
        }}
        className="table-pagination"
      />
    </div>
  );
};

export default TableComponent;
