import React, { useEffect, useState } from "react";
import { get } from "../../../redux/ApiHelper";
import { GcTransactionInterface } from "../../../interfaces/guruCoin.interface";
import { Column } from "../../../interfaces/table.interface";
import TableComponent from "../../../components/table/TableComponent";
import { formatDateTime } from "../../../utils/date&time.util";

const GuruCoinTransaction = () => {
  const [gcTransactionList, setGcTransactionList] = useState<
    GcTransactionInterface[]
  >([]);
  const [filteredGcTransactionList, setFilteredGcTransactionList] = useState<
    GcTransactionInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    get("/admin/gc-transaction/all").then((res) => {
      setGcTransactionList(res);
      setFilteredGcTransactionList(res);
    });
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = gcTransactionList?.filter((transaction) => {
      const parentName = transaction.parentId?.name?.toLowerCase() || "";
      const teacherName = transaction.teacherId?.name?.toLowerCase() || "";
      const studentName = transaction.studentId?.name?.toLowerCase() || "";
      const classNumber =
        transaction?.classId?.currentTuitionClassNo?.toString() || "";

      return (
        transaction?._id?.toLowerCase().includes(lowerQuery) ||
        transaction?.coins?.toString().includes(lowerQuery) ||
        transaction.teacherValue?.toString().includes(lowerQuery) ||
        parentName.includes(lowerQuery) ||
        teacherName.includes(lowerQuery) ||
        studentName.includes(lowerQuery) ||
        classNumber.includes(lowerQuery)
      );
    });

    setFilteredGcTransactionList(filteredList);
  };

  const columns: Column[] = [
    { select: "_id", header: "Transaction ID", align: "left" },
    {
      select: "coins",
      header: "Coins",
    },
    {
      select: "teacherValue",
      header: "INR value",
    },
    {
      header: "Date & Time",
      render: (_value: any, row: GcTransactionInterface) =>
        formatDateTime(row?.createdAt),
    },
    // {
    //   select: "status",
    //   header: "Status",
    //   align: "center",
    //   render: (value: string) => (
    //     <span
    //       className={`status-badge ${
    //         value === "Completed"
    //           ? "bg-green-500"
    //           : value === "Pending"
    //           ? "bg-yellow-500"
    //           : "bg-red-500"
    //       } text-white px-2 py-1 rounded`}
    //     >
    //       {value}
    //     </span>
    //   ),
    // },
    {
      header: "Parent Name",
      render: (_value: any, row: GcTransactionInterface) =>
        row?.parentId?.name || "N/A",
    },
    {
      header: "Teacher Name",
      render: (_value: any, row: GcTransactionInterface) =>
        row?.teacherId?.name || "N/A",
    },
    {
      header: "Student Name",
      render: (_value: any, row: GcTransactionInterface) =>
        row?.studentId?.name || "N/A",
    },
    {
      header: "Class ID",
      render: (_value: any, row: GcTransactionInterface) =>
        row?.classId?._id || "N/A",
    },
  ];

  return (
    <div className="gcTransaction table-container">
      <div className="title">Guru Coin Transactions</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search transactions"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredGcTransactionList} />
    </div>
  );
};

export default GuruCoinTransaction;
