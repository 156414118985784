import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CouponForm, CouponInterface } from "../../interfaces/coupon.interface";
import { get, post, put } from "../ApiHelper";

interface CouponState {
  status: string;
  couponList: CouponInterface[];
}

const initialState: CouponState = {
  status: "idle",
  couponList: [],
};

// Async actions
export const getCoupons = createAsyncThunk("get-coupons", async () => {
  try {
    const response = await get("/coupon/all");
    return response;
  } catch (error) {
    throw error;
  }
});

export const createCoupon = createAsyncThunk(
  "create-coupon",
  async (data: CouponForm) => {
    try {
      const response = await post("/coupon/create", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateCoupon = createAsyncThunk(
  "update-coupon",
  async ({ id, data }: { id: string; data: Partial<CouponForm> }) => {
    try {
      const response = await put(`/coupon/update/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Slice
const CouponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get all coupons
      .addCase(getCoupons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCoupons.fulfilled, (state, action) => {
        state.couponList = action.payload;
        state.status = "success";
      })
      .addCase(getCoupons.rejected, (state) => {
        state.status = "reject";
      })

      // Create coupon
      .addCase(createCoupon.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCoupon.fulfilled, (state, action) => {
        state.couponList.push(action.payload);
        state.status = "success";
      })
      .addCase(createCoupon.rejected, (state) => {
        state.status = "reject";
      })

      // Update coupon
      .addCase(updateCoupon.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        state.couponList = state.couponList.map((coupon) =>
          coupon._id === action.payload._id ? action.payload : coupon
        );
        state.status = "success";
      })
      .addCase(updateCoupon.rejected, (state) => {
        state.status = "reject";
      });
  },
});

export default CouponSlice.reducer;
