import { useEffect, useState } from "react";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  createButton,
  Editor,
  EditorProvider,
  HtmlButton,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";
import "./PageContent.scss";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../redux/slices/WindowSlice";
import { get, post } from "../../../redux/ApiHelper";
import { PageType } from "../../../enums/page-type.enum";
import { SketchPicker } from "react-color";

const BtnAlignLeft = createButton("Align left", "<≡", "justifyLeft");
const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");
const BtnAlignRight = createButton("Align right", "≡>", "justifyRight");

const PageContent = ({ page, title }: { page: PageType; title: string }) => {
  const [color, setColor] = useState<string>("#000000");
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const BtnColor: any = createButton("Set Color", "🎨", () =>
    document.execCommand("foreColor", false, color)
  );

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const [value, setValue] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const res = await get("/admin/page-content/" + page);
      setValue(res.content);
    })();
    // eslint-disable-next-line
  }, []);

  const onChange = (e: any) => setValue(e.target.value);

  const save = async () => {
    dispatch(startLoading());
    await post("/admin/page-content/" + page, { content: value }).finally(
      () => {
        dispatch(stopLoading());
      }
    );
  };

  const handleColorChange = (color: any) => {
    setColor(color.hex);
    togglePicker();
  };

  return (
    <div className="page-content">
      <h1
        style={{
          color: "gray",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "16px",
        }}
      >
        {title}
      </h1>
      <EditorProvider>
        <Editor
          value={value}
          onChange={onChange}
          containerProps={{ style: { textAlign: "left", maxHeight: "400px" } }}
        >
          <Toolbar>
            <BtnUndo />
            <BtnRedo />
            <Separator />
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnNumberedList />
            <BtnBulletList />
            <Separator />
            <BtnLink />
            <BtnClearFormatting />
            <HtmlButton />
            <Separator />
            <BtnAlignLeft />
            <BtnAlignCenter />
            <BtnAlignRight />
            <BtnColor onClick={togglePicker} />
            <BtnStyles />
            <button
              onClick={save}
              style={{ float: "right" }}
              className="px-2 py-0 mr-2 ml-auto text-gray-700 bg-yellow-400 rounded-md hover:bg-yellow-600 transition"
            >
              Save
            </button>
          </Toolbar>
        </Editor>
      </EditorProvider>

      {value ? (
        <div className="content" dangerouslySetInnerHTML={{ __html: value }} />
      ) : null}

      <div
        style={{ display: showPicker ? "block" : "none" }}
        className="color-picker-container"
      >
        <SketchPicker
          className="color-picker"
          color={color}
          onChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default PageContent;
