import React, { useEffect, useState } from "react";
import "./Student.scss";
import { get } from "../../redux/ApiHelper";
import { Student } from "../../interfaces/student.interface";
import TableComponent from "../../components/table/TableComponent";
import { Column } from "../../interfaces/table.interface";

const StudentList = () => {
  const [originalList, setOriginalList] = useState<Student[]>([]);
  const [studentList, setStudentList] = useState<Student[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    get("/student/get-all").then((res) => {
      setOriginalList(res);
      setStudentList(res);
    });
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);

    if (query.trim() === "") {
      setStudentList(originalList); // Reset to the original list
      return;
    }

    const lowerQuery = query.toLowerCase();
    const filteredList = originalList.filter((student) => {
      return (
        student.name?.toLowerCase().includes(lowerQuery) ||
        student.grade?.toString().toLowerCase().includes(lowerQuery) ||
        student.dob?.toLowerCase().includes(lowerQuery) ||
        student.board?.toLowerCase().includes(lowerQuery) ||
        student.gender?.toLowerCase().includes(lowerQuery) ||
        student.institute?.toLowerCase().includes(lowerQuery) ||
        student.subject?.toLowerCase().includes(lowerQuery)
      );
    });

    setStudentList(filteredList);
  };

  const columns: Column[] = [
    {
      select: "profilePicture",
      header: "Profile Picture",
      render: (value: string) => (
        <img src={value} alt="Profile" className="profile-img" />
      ),
    },
    { select: "name", header: "Name", align: "left" },
    { select: "dob", header: "Date of Birth" },
    { select: "gender", header: "Gender" },
    { select: "institute", header: "Institute" },
    { select: "grade", header: "Grade" },
    { select: "board", header: "Board" },
    {
      select: "subject",
      header: "Subjects",
      render: (value: string) => value || "N/A",
    },
  ];

  return (
    <div className="student table-container">
      <div className="title">Student List</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search student"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <TableComponent columns={columns} data={studentList} />
    </div>
  );
};

export default StudentList;
