import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText, Collapse } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SidebarMenu } from "./sidebarMenu";
import { FiLogOut } from "react-icons/fi";

const Sidebar: React.FC = () => {
  const location = useLocation(); // Get current route location
  const navigate = useNavigate();
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

  const toggleMenu = (menu: string) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const isActive = (path: string): boolean => location.pathname === path; // Check if the menu's path matches the current route

  const handleLogout = () => {
    // Implement your logout logic here, like clearing tokens or redirecting
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="sidebar">
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiListItem-gutters ": {
            transition: "background-color 0.3s",
          },
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            backgroundColor: "#171717",
            borderRight: "1px solid var(--color-primary)",
          },
        }}
        variant="permanent"
        className="drawer flex flex-column"
        open
      >
        <List>
          {SidebarMenu.map((item) => (
            <React.Fragment key={item.menu}>
              <ListItem
                component={item.path ? Link : "div"}
                to={item.path || undefined}
                onClick={() => {
                  if (item?.subMenu) toggleMenu(item.menu);
                }}
                sx={{
                  backgroundColor: isActive(item.path || "")
                    ? "var(--color-dark-gray)" // Active menu background color
                    : "var(--color-dark)", // Default background color
                  color: isActive(item.path || "")
                    ? "var(--color-primary)" // Active menu text color
                    : "var(--color-light-gray)", // Default text color
                  "&:hover": {
                    backgroundColor: "var(--color-gray)", // Hover background color
                    color: "var(--color-primary)", // Hover text color
                  },
                }}
              >
                <ListItemText primary={item.menu} />
                {item.subMenu && (
                  <>
                    {openMenus[item.menu] ? <FaChevronUp /> : <FaChevronDown />}
                  </>
                )}
              </ListItem>

              {/* Handle Submenu */}
              {item.subMenu && (
                <Collapse
                  in={openMenus[item.menu]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subMenu.map((subItem) => (
                      <React.Fragment key={subItem.menu}>
                        <ListItem
                          component={Link}
                          to={subItem.path}
                          onClick={() => {
                            if (subItem.subMenu) toggleMenu(subItem.menu); // Toggle third-level menu
                          }}
                          sx={{
                            pl: 4,
                            backgroundColor: isActive(subItem.path)
                              ? "var(--color-dark-gray)" // Active sub-menu background color
                              : "transparent", // Default sub-menu background color
                            color: isActive(subItem.path)
                              ? "var(--color-primary)" // Active sub-menu text color
                              : "var(--color-light-gray)", // Default sub-menu text color
                            "&:hover": {
                              backgroundColor: "var(--color-gray)", // Hover background color
                              color: "var(--color-primary)", // Hover text color
                            },
                          }}
                        >
                          <ListItemText primary={subItem.menu} />
                          {subItem.subMenu && (
                            <>
                              {openMenus[subItem.menu] ? (
                                <FaChevronUp />
                              ) : (
                                <FaChevronDown />
                              )}
                            </>
                          )}
                        </ListItem>

                        {/* Handle Third-level Submenu (Submenu under Submenu) */}
                        {subItem.subMenu && (
                          <Collapse
                            in={openMenus[subItem.menu]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {subItem.subMenu.map((subSubItem) => (
                                <ListItem
                                  key={subSubItem.menu}
                                  component={Link}
                                  to={subSubItem.path}
                                  sx={{
                                    pl: 6,
                                    backgroundColor: isActive(subSubItem.path)
                                      ? "var(--color-dark-gray)" // Active sub-sub-menu background color
                                      : "transparent", // Default background color
                                    color: isActive(subSubItem.path)
                                      ? "var(--color-primary)" // Active sub-sub-menu text color
                                      : "var(--color-light-gray)", // Default text color
                                    "&:hover": {
                                      backgroundColor: "var(--color-gray)", // Hover background color
                                      color: "var(--color-primary)", // Hover text color
                                    },
                                  }}
                                >
                                  <ListItemText primary={subSubItem.menu} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          style={{ width: "calc(100% - 32px)" }}
          className="mt-auto mx-auto mb-4 flex items-center justify-center px-4 py-3 bg-red-600 text-white text-sm font-medium shadow-md transition-all duration-300 ease-in-out hover:bg-yellow-500 hover:text-black hover:shadow-lg active:scale-95 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
        >
          <FiLogOut className="mr-2 h-5 w-5" />
          Logout
        </button>
      </Drawer>
    </div>
  );
};

export default Sidebar;
