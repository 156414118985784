import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { MdOpenInNew } from "react-icons/md";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/table/TableComponent";
import { TuitionRequestInterface } from "../../../interfaces/tuitionRequest.interface";
import { Column } from "../../../interfaces/table.interface";
import { formatDateTime } from "../../../utils/date&time.util";

const GeneralRequest = () => {
  const {
    generalTuitionRequests,
  }: { generalTuitionRequests: TuitionRequestInterface[] } = useSelector(
    (state: {
      tuitionRequest: { generalTuitionRequests: TuitionRequestInterface[] };
    }) => state.tuitionRequest
  );

  // Search state
  const [search, setSearch] = useState("");

  // Filtered data based on search input
  const filteredRequests = generalTuitionRequests?.filter((request) => {
    const searchText = search.toLowerCase();
    const formattedDate = formatDateTime(request.createdAt)?.toLowerCase();
    return (
      request.parent?.name?.toLowerCase().includes(searchText) ||
      request.student?.name?.toLowerCase().includes(searchText) ||
      request.preferredSubjects?.some((subject) =>
        subject.toLowerCase().includes(searchText)
      ) ||
      formattedDate.includes(searchText) // Match formatted date & time
    );
  });

  const columns: Column[] = [
    {
      header: "Parent",
      align: "left",
      render: (_value: any, row: TuitionRequestInterface) => row?.parent?.name,
    },
    {
      header: "Student",
      render: (_value: any, row: TuitionRequestInterface) => row?.student?.name,
    },
    {
      select: "preferredSubjects",
      header: "Preferred Subjects",
      render: (value: string[]) => value.join(", "),
    },
    {
      header: "Date & Time",
      render: (_value: any, row: TuitionRequestInterface) =>
        formatDateTime(row?.createdAt),
    },
    {
      select: "_id",
      header: "Details",
      render: (value: string) => (
        <Link
          to={`/request/${value}`}
          style={{ textDecoration: "none" }}
          className="flex"
        >
          <IconButton
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "#1976d2",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              margin: "auto",
            }}
          >
            <MdOpenInNew size={20} />
          </IconButton>
        </Link>
      ),
    },
  ];

  return (
    <div className="">
      {/* Search Bar */}
      <div className="px-4 py-2">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search requests..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {/* Table Component */}
      <TableComponent columns={columns} data={filteredRequests} />
    </div>
  );
};

export default GeneralRequest;
