import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../ApiHelper";
import { InfoInterface } from "../../interfaces/info.interface";

interface InfoState {
  status: string;
  updateStatus: string;
  info: InfoInterface | null; // Stores the current info settings
}

const initialState: InfoState = {
  status: "idle",
  updateStatus: "idle",
  info: null,
};

// Async thunk to fetch info
export const getInfo = createAsyncThunk("info/get", async () => {
  try {
    const response = await get("/info");
    return response;
  } catch (error) {
    throw error;
  }
});

// Async thunk to update info
export const updateInfo = createAsyncThunk(
  "info/update",
  async (data: Partial<InfoInterface>) => {
    try {
      const response = await post("/info", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const InfoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch info
      .addCase(getInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.info = action.payload;
        state.status = "success";
      })
      .addCase(getInfo.rejected, (state) => {
        state.status = "rejected";
      })

      // Update info
      .addCase(updateInfo.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateInfo.fulfilled, (state, action) => {
        state.info = action.payload;
        state.updateStatus = "success";
      })
      .addCase(updateInfo.rejected, (state) => {
        state.updateStatus = "rejected";
      });
  },
});

// Export the reducer for usage in the store
export default InfoSlice.reducer;
