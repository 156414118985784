import "./Loader.scss";

const Loader: React.FC = () => {
  const logo = "/logo.svg";
  return (
    <div className="app-loader" id="app-loader">
      <div className="loader-container">
        <div className="loader">
          <div className="loader-border"></div>
          {logo && (
            <img src={logo} alt="Loading logo" className="loader-logo" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Loader;
