// src/ToastInitializer.tsx
import { useEffect } from "react";
import { useToast } from "./ToastContext";
import { setToastFunctions } from "../../utils/toast.util";

const ToastInitializer: React.FC = () => {
  const { showToast } = useToast();

  useEffect(() => {
    // Initialize the showToast function globally
    setToastFunctions({ showToast });
  }, [showToast]);

  return null;
};

export default ToastInitializer;
