import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TuitionRequestInterface } from "../../interfaces/tuitionRequest.interface";
import { get, put } from "../ApiHelper";

interface TuitionRequestState {
  status: string;
  allTuitionRequests: TuitionRequestInterface[];
  specificTuitionRequests: TuitionRequestInterface[];
  generalTuitionRequests: TuitionRequestInterface[];
  historyTuitionRequests: TuitionRequestInterface[];
}

const initialState: TuitionRequestState = {
  status: "idle",
  allTuitionRequests: [],
  specificTuitionRequests: [],
  generalTuitionRequests: [],
  historyTuitionRequests: [],
};

// Async thunk for fetching tuition requests
export const getTuitionRequests = createAsyncThunk(
  "get-tuition-requests",
  async () => {
    try {
      const response = await get("/admin/tuition/tuition-requests");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for assigning a teacher
export const assignTeacher = createAsyncThunk(
  "assign-teacher",
  async (
    {
      id,
      teacherId,
      schedule,
    }: { id: string; teacherId: string; schedule: any[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await put(
        `/tuition/tuition-request/${id}/assign-teacher`,
        {
          teacherId,
          schedule,
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for changing request status
export const changeRequestStatus = createAsyncThunk(
  "change-request-status",
  async (
    {
      id,
      status,
      teacher,
      totalNumberOfClasses,
      subjects,
      guruCoinCharge,
    }: {
      id: string;
      status: string;
      teacher: string;
      totalNumberOfClasses: number;
      subjects: string[];
      guruCoinCharge: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await put(
        `/admin/tuition/tuition-request/${id}/status`,
        {
          status,
          teacher,
          totalNumberOfClasses,
          subjects,
          guruCoinCharge,
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const TuitionRequestSlice = createSlice({
  name: "tuition-request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTuitionRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTuitionRequests.fulfilled, (state, action) => {
        state.specificTuitionRequests =
          action.payload.teacherSpecificTuitionRequests;
        state.generalTuitionRequests = action.payload.generalTuitionRequests;
        state.historyTuitionRequests = action.payload.historyTuitionRequests;
        state.allTuitionRequests = [
          ...state.specificTuitionRequests,
          ...state.generalTuitionRequests,
        ];
        state.status = "success";
      })

      .addCase(getTuitionRequests.rejected, (state) => {
        state.status = "reject";
      })
      .addCase(changeRequestStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeRequestStatus.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(changeRequestStatus.rejected, (state, action) => {
        console.error("Error changing request status:", action.payload);
      });
  },
});

export default TuitionRequestSlice.reducer;
