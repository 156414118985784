import React, { useEffect, useState } from "react";
import TableComponent from "../../components/table/TableComponent";
import { MdAdd, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import { FormControlLabel, Switch } from "@mui/material";
import { successToast } from "../../utils/toast.util";
import { Column } from "../../interfaces/table.interface";
import { GuruCoinInterface } from "../../interfaces/guruCoin.interface";
import {
  getGuruCoinPackages,
  updateGuruCoinPackage,
} from "../../redux/slices/GuruCoinSlice";
import CreateGuruCoinModal from "./CreateGuruCoinModal";
import { RootState } from "../../redux/store";

const GuruCoinPackage = () => {
  const dispatch: DispatchType = useDispatch();
  const { guruCoinPackages }: { guruCoinPackages: GuruCoinInterface[] } =
    useSelector((state: RootState) => state.guruCoinPackage);
  const [filteredGuruCoinPackages, setFilteredGuruCoinPackages] = useState<
    GuruCoinInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<GuruCoinInterface>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getGuruCoinPackages());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (guruCoinPackages.length > 0) {
      setFilteredGuruCoinPackages(guruCoinPackages);
    }
  }, [guruCoinPackages]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = guruCoinPackages?.filter((packageData) => {
      return (
        packageData._id?.toLowerCase().includes(lowerQuery) || // Search by ID
        packageData.coins?.toString().includes(lowerQuery) || // Search by Coin Count
        packageData.teacherValue?.toString().includes(lowerQuery) || // Search by Teacher Value
        packageData.platformCharge?.toString().includes(lowerQuery) || // Search by Platform Charge
        packageData.gst?.toString().includes(lowerQuery) || // Search by GST
        packageData.paidAmount?.toString().includes(lowerQuery) || // Search by Paid Amount
        (packageData.isActive ? "active" : "inactive").includes(lowerQuery) // Search by Status
      );
    });

    setFilteredGuruCoinPackages(filteredList);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null);
    setIsEdit(false);
  };

  const columns: Column[] = [
    { select: "coins", header: "Coins" },
    { select: "teacherValue", header: "Teacher wallet in INR" },
    { select: "platformCharge", header: "Platform Charge" },
    { select: "gst", header: "GST (%)" },
    { select: "discount", header: "Discount (INR)" },
    { select: "paidAmount", header: "Paid Amount" },
    {
      select: "isActive",
      header: "Status",
      render: (value: boolean, rowData: GuruCoinInterface) => (
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={value}
              onChange={(e) =>
                dispatch(
                  updateGuruCoinPackage({
                    id: rowData._id,
                    data: { isActive: e.target.checked },
                  })
                )
                  .unwrap()
                  .then(() => {
                    successToast("Status updated successfully");
                  })
              }
            />
          }
          label="Active"
          labelPlacement="end"
        />
      ),
    },
    {
      header: "Edit",
      render: (value: boolean, rowData: GuruCoinInterface) => (
        <div
          className="rounded-full bg-slate-200 p-2 text-yellow-500 cursor-pointer"
          onClick={() => {
            setEditData(rowData);
            setIsEdit(true);
            setIsModalOpen(true);
          }}
        >
          <MdEdit className="m-auto" />
        </div>
      ),
    },
  ];

  return (
    <div className="guru-coin table-container">
      <div className="title">Guru Coin Packages</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search by coins, value, or charge"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredGuruCoinPackages} />

      {/* Floating Action Button */}
      <div
        onClick={openModal}
        className="fixed top-8 right-8 bg-yellow-500 text-white p-2 rounded-full cursor-pointer shadow-lg hover:bg-yellow-600"
      >
        <MdAdd />
      </div>

      {/* Create Guru Coin Modal */}
      <CreateGuruCoinModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        isEdit={isEdit}
        editData={editData}
      />
    </div>
  );
};

export default GuruCoinPackage;
