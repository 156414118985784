import React, { useEffect, useState } from "react";
import "./Request.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TableContainer from "@mui/material/TableContainer";
import { getTuitionRequests } from "../../redux/slices/TuitionRequestSlice";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import TeacherSpecificRequest from "./teacher/TeacherSpecificRequest";
import GeneralRequest from "./teacher/GeneralRequest";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import { getTeachers } from "../../redux/slices/TeacherSlice";
import RequestHistory from "./teacher/RequestHistory";

const Request = () => {
  const dispatch: DispatchType = useDispatch();
  const [teacherSubTab, setTeacherSubTab] = useState("1");

  const handleTeacherSubTabChange = (event, newValue) => {
    setTeacherSubTab(newValue);
  };

  useEffect(() => {
    // Fetch data from API
    dispatch(startLoading());
    dispatch(getTuitionRequests()).finally(() => {
      dispatch(getTeachers());
      dispatch(stopLoading());
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="request">
      <div className="title">Tuition Requests</div>
      <div className="container">
        <TabContext value={teacherSubTab}>
          <Box
            className="mb-3"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              onChange={handleTeacherSubTabChange}
              aria-label="Teacher subtabs"
              sx={{
                "& .MuiTab-root": {
                  color: "var(--color-light-gray)",
                  "&:hover": {
                    color: "var(--color-primary)!important",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px 8px 0 0",
                  },
                },
                "& .Mui-selected": {
                  color: "var(--color-primary)!important",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  fontWeight: "bold",
                  borderRadius: "8px 8px 0 0",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--color-primary)",
                },
              }}
            >
              <Tab label="Specific" value="1" />
              <Tab label="General" value="2" />
              <Tab label="History" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TableContainer>
              <TeacherSpecificRequest />
            </TableContainer>
          </TabPanel>
          <TabPanel value="2">
            <TableContainer>
              <GeneralRequest />
            </TableContainer>
          </TabPanel>
          <TabPanel value="3">
            <TableContainer>
              <RequestHistory />
            </TableContainer>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default Request;
