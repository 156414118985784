import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TeacherList.scss";
import { TeacherInterface } from "../../interfaces/teacher.interface";
import TableComponent from "../../components/table/TableComponent";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Column } from "../../interfaces/table.interface";
import { FiFileText } from "react-icons/fi";
import { IoArrowRedo } from "react-icons/io5";
import { DispatchType } from "../../interfaces/redux.interface";
import { useDispatch, useSelector } from "react-redux";
import { getTeachers } from "../../redux/slices/TeacherSlice";
import { RootState } from "../../redux/store";
import TeacherStatusHandler from "./components/TeacherStatusHandler";

const TeacherList = () => {
  const dispatch: DispatchType = useDispatch();
  const [filteredTeacherList, setFilteredTeacherList] = useState<
    TeacherInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const { allTeachers } = useSelector((state: RootState) => state.teacher);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTeachers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredTeacherList(allTeachers);
  }, [allTeachers]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = allTeachers?.filter((teacher) => {
      const address = teacher.address
        ? `${teacher.address.addressLine} ${teacher.address.city} ${teacher.address.state} ${teacher.address.pinCode}`
        : "";
      return (
        teacher.name?.toLowerCase().includes(lowerQuery) ||
        teacher.phoneNumber?.toString().toLowerCase().includes(lowerQuery) ||
        teacher.email?.toLowerCase().includes(lowerQuery) ||
        teacher.subjects?.some((subject) =>
          subject.toLowerCase().includes(lowerQuery)
        ) ||
        address.toLowerCase().includes(lowerQuery)
      );
    });

    setFilteredTeacherList(filteredList);
  };

  const handleViewDocuments = (teacher: TeacherInterface) => {
    let documents = [];
    documents.push(teacher?.highestQualification?.docUrl);
    documents.push(teacher?.otherDocs?.map((doc) => doc?.docUrl));
    setSelectedDocuments(documents);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDocuments([]);
  };

  const columns: Column[] = [
    {
      select: "profilePicture",
      header: "Profile Picture",
      render: (value: string) => (
        <img src={value} alt="Profile" className="profile-img" />
      ),
    },
    { select: "name", header: "Name", align: "left" },
    { select: "phoneNumber", header: "Phone Number" },
    {
      select: "address",
      header: "Address",
      render: (value: any) =>
        value
          ? `${value.addressLine}, ${value.city}, ${value.state}, ${value.pinCode}`
          : "N/A",
    },
    { select: "email", header: "Email" },
    {
      select: "subjects",
      header: "Subjects",
      render: (value: string[]) => value?.join(", "),
    },
    {
      select: "documents",
      header: "Documents",
      render: (value: string[], rowData: TeacherInterface) => (
        <button
          onClick={() => handleViewDocuments(rowData)}
          className="flex items-center gap-2 px-3 py-1.5 bg-blue-500 text-white text-sm font-medium rounded hover:bg-blue-600 transition m-auto"
        >
          <FiFileText className="text-lg" />
        </button>
      ),
    },
    {
      select: "isActive",
      header: "Status",
      render: (value: boolean, rowData: TeacherInterface) => (
        <TeacherStatusHandler teacher={rowData} />
      ),
    },
    {
      header: "Details",
      render: (_: any, row: TeacherInterface) => (
        <button
          onClick={() => navigate(`/teacher/${row._id}`)}
          className="flex items-center gap-2 p-2 bg-slate-500 text-white text-sm font-medium rounded-full hover:bg-slate-600 transition m-auto"
        >
          <IoArrowRedo className="m-auto" />
        </button>
      ),
    },
  ];

  return (
    <div className="teacher table-container">
      <div className="title">Teacher List</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search teacher"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredTeacherList} />

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Teacher Documents</DialogTitle>
        <DialogContent>
          {selectedDocuments?.length > 0 ? (
            <ul className="documents-list">
              {selectedDocuments?.map((doc, index) => {
                const isImage = /\.(jpg|jpeg|png|gif)$/i.test(doc); // Check if the file is an image
                const isPDF = /\.pdf$/i.test(doc); // Check if the file is a PDF

                return (
                  <li key={index}>
                    {isImage ? (
                      // Render as an image
                      <a href={doc} target="_blank" rel="noopener noreferrer">
                        <img
                          src={doc}
                          alt={`Document ${index}`}
                          className="doc-image"
                        />
                      </a>
                    ) : (
                      isPDF && (
                        // Render as a PDF link
                        <a href={doc} target="_blank" rel="noopener noreferrer">
                          View PDF Document {index}
                        </a>
                      )
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No documents available</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TeacherList;
