import { useState } from "react";
import { Link } from "react-router-dom";
import { TuitionRequestInterface } from "../../../../interfaces/tuitionRequest.interface";

const ApprovedRequestDetails = ({
  tuitionRequest,
}: {
  tuitionRequest: TuitionRequestInterface;
}) => {
  const [gcChargePerClass, setGcChargePerClass] = useState(
    tuitionRequest?.tuitionPeriod?.gcChargePerClass?.coin || 0
  );
  const [classCoins, setClassCoins] = useState(
    tuitionRequest?.tuitionClasses?.map((cls) => ({
      id: cls._id,
      coin: cls?.gcChargePerClass?.coin || 0,
    })) || []
  );

  const handlePeriodCoinChange = (event) => {
    setGcChargePerClass(event.target.value);
    console.log("Updated period coin charge:", event.target.value);
    // Trigger an API call to update the period coin charge here
  };

  const handleClassCoinChange = (id, value) => {
    setClassCoins((prev) =>
      prev.map((cls) => (cls.id === id ? { ...cls, coin: value } : cls))
    );
    console.log(`Updated coin charge for class ${id}:`, value);
    // Trigger an API call to update the individual class coin charge here
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Teacher Details */}
        <div className="mb-4 p-4 border rounded-lg shadow-sm bg-white">
          <div className="mb-2">
            <strong className="text-xl text-yellow-500">
              Assigned Teacher:
            </strong>
          </div>
          <div className="text-gray-800 font-medium space-y-3">
            <div className="flex flex-col space-y-2">
              <div className="text-lg font-semibold">
                {tuitionRequest?.preferredTeacher?.name || "Not Assigned"}
              </div>
              <div className="text-sm text-gray-600">
                {tuitionRequest?.preferredTeacher?.gender ||
                  "Gender not specified"}
              </div>
              <div className="text-sm text-gray-600">
                {tuitionRequest?.preferredTeacher?.phoneNumber ||
                  "Not Available"}{" "}
                -{" "}
                {tuitionRequest?.preferredTeacher?.address?.city ||
                  "No address"}
              </div>
              <div className="text-sm text-gray-600">
                {tuitionRequest?.preferredTeacher?.email ||
                  "Email not available"}
              </div>
              <div className="text-sm text-gray-600">
                {tuitionRequest?.preferredTeacher?.highestQualification
                  ?.degreeName || "Qualification not available"}
              </div>
              <div className="text-sm text-gray-600">
                {tuitionRequest?.preferredTeacher?.yearOfExperience
                  ? `${tuitionRequest?.preferredTeacher?.yearOfExperience} years of experience`
                  : "Experience not specified"}
              </div>
              <Link
                to={`/teacher/${tuitionRequest?.preferredTeacher?._id}`}
                className="inline-block mt-4 px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-400"
              >
                View Teacher's Details
              </Link>
            </div>
          </div>
        </div>

        {/* Parent and Student Details */}
        <div className="mb-4 p-4 border rounded-lg shadow-sm bg-white">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <strong className="text-yellow-500">Parent:</strong>
              <span className="text-gray-800 font-medium">
                {tuitionRequest?.parent?.name || "Not Specified"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <strong className="text-yellow-500">Student:</strong>
              <span className="text-gray-800 font-medium">
                {tuitionRequest?.student?.name || "Not Specified"}
              </span>
            </div>
          </div>
        </div>

        {/* Tuition Period */}
        <div className="mb-4 p-4 border rounded-lg shadow-sm bg-white">
          <div className="mb-2">
            <strong className="text-xl text-yellow-500">Tuition Period:</strong>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <strong className="text-yellow-500">
                Total Number of Classes:
              </strong>
              <span className="text-gray-800 font-medium">
                {tuitionRequest?.requiredNumberOfClasses || "Not Specified"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <strong className="text-yellow-500">Subjects:</strong>
              <span className="text-gray-800 font-medium">
                {tuitionRequest?.preferredSubjects?.join(", ") ||
                  "No subjects specified"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <strong className="text-yellow-500">
                Coin Charge per Class:
              </strong>
              <span className="text-gray-800 font-medium">
                {tuitionRequest?.tuitionPeriod?.gcChargePerClass?.coin || 0}
              </span>
            </div>
            {!tuitionRequest?.tuitionClasses?.length && (
              <div className="flex items-center space-x-2">
                <strong className="text-yellow-500">Update Coin Charge:</strong>
                <input
                  type="number"
                  value={gcChargePerClass}
                  onChange={handlePeriodCoinChange}
                  className="border rounded px-3 py-2 text-gray-800"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Tuition Classes */}
      <div className="mb-4 p-4 border rounded-lg shadow-sm bg-white">
        {tuitionRequest?.tuitionClasses?.length > 0 ? (
          <div>
            <div className="mb-4">
              <strong className="text-xl text-yellow-500">
                Tuition Classes:
              </strong>
            </div>
            {tuitionRequest?.tuitionClasses?.map((cls, index) => (
              <div
                key={cls._id}
                className="p-4 mb-2 border rounded-lg bg-gray-50 text-left"
              >
                <div className="flex items-center justify-between">
                  <div>
                    {/* Displaying class details without subject */}
                    <div className="text-lg font-medium">
                      Class {cls.currentTuitionClassNo}
                    </div>
                    <div className="text-sm text-gray-600">
                      <strong>Scheduled on:</strong>
                      {cls.tuitionClassSchedule?.day ||
                        "Day not specified"} -{" "}
                      {cls.tuitionClassSchedule?.date || "Date not specified"}
                    </div>
                    <div className="text-sm text-gray-600">
                      <strong>Guru Coin Charge:</strong>{" "}
                      {cls.gcChargePerClass?.coin || "Not specified"}
                    </div>
                  </div>

                  {/* Displaying coin input only if needed */}
                  <div>
                    <input
                      type="number"
                      value={
                        classCoins.find((coinObj) => coinObj.id === cls._id)
                          ?.coin ||
                        cls.gcChargePerClass?.coin ||
                        0
                      }
                      onChange={(e) =>
                        handleClassCoinChange(cls._id, e.target.value)
                      }
                      className="border rounded px-3 py-2 text-gray-800"
                      disabled={cls.isGuruCoinDeducted}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-gray-600 font-medium text-center">
            No classes created yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovedRequestDetails;
