import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import {
  GuruCoinForm,
  GuruCoinInterface,
} from "../../interfaces/guruCoin.interface";
import {
  createGuruCoinPackage,
  updateGuruCoinPackage,
} from "../../redux/slices/GuruCoinSlice";
import { RootState } from "../../redux/store";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import { getInfo } from "../../redux/slices/InfoSlice";

interface GuruCoinModalProps {
  isOpen: boolean;
  closeModal: () => void;
  isEdit?: boolean;
  editData?: GuruCoinInterface;
}

const CreateGuruCoinModal = ({
  isOpen,
  closeModal,
  isEdit,
  editData,
}: GuruCoinModalProps) => {
  const dispatch: DispatchType = useDispatch();
  const { info } = useSelector((state: RootState) => state.info);

  const [coinData, setCoinData] = useState<GuruCoinForm>({
    coins: null,
    teacherValue: null,
    platformCharge: 0,
    gst: 18,
    discount: null,
    validity: 30,
    paidAmount: 0,
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState({
    coins: "",
    teacherValue: "",
    platformCharge: "",
    paidAmount: "",
    validity: "",
    discount: "",
  });

  useEffect(() => {
    if (isEdit && editData) {
      setCoinData(editData);
    } else {
      setCoinData({
        coins: null,
        teacherValue: null,
        platformCharge: null,
        gst: 18,
        discount: null,
        validity: 30,
        paidAmount: null,
      });
    }
  }, [isEdit, editData]);

  // Fetch info on component mount
  useEffect(() => {
    if (!info) {
      dispatch(startLoading());
      dispatch(getInfo()).finally(() => {
        dispatch(stopLoading());
      });
    }

    //eslint-disable-next-line
  }, [info]);

  useEffect(() => {
    const { gcValue, inrValue } = info?.conversionRate || {};
    const { coins, platformCharge, gst, discount } = coinData;

    let calculatedPaidAmount = 0;

    // Calculate teacherValue based on coin and conversion rate
    const teacherValue = gcValue && inrValue ? (coins * inrValue) / gcValue : 0;

    // Initialize calculatedPaidAmount with teacherValue
    calculatedPaidAmount = teacherValue;

    // Handle platform charge + GST if available
    if (platformCharge > 0 && gst >= 0) {
      const platformChargeWithGST =
        platformCharge + (platformCharge * gst) / 100;
      calculatedPaidAmount += platformChargeWithGST;
    }

    // Apply discount if present and valid
    if (discount && discount >= 0) {
      calculatedPaidAmount -= discount;
    }

    // Update coinData with calculated teacherValue and paidAmount
    setCoinData((prevData) => ({
      ...prevData,
      teacherValue,
      paidAmount: calculatedPaidAmount,
    }));
    // eslint-disable-next-line
  }, [
    coinData.coins,
    coinData.platformCharge,
    coinData.gst,
    coinData.discount,
    info?.conversionRate,
  ]);

  useEffect(() => {
    const {
      coins,
      teacherValue,
      platformCharge,
      validity,
      discount,
      paidAmount,
    } = coinData;
    // Validate inputs and set errors
    const newErrors = {
      coins: coins < 1 ? "Coins must be at least 1" : "",
      teacherValue:
        teacherValue < 0 ? "Teacher value must be non-negative" : "",
      platformCharge:
        platformCharge < 0 ? "Platform charge must be non-negative" : "",
      discount: discount && discount < 0 ? "Discount must be non-negative" : "",
      paidAmount: paidAmount <= 0 ? "Paid amount must be greater than 0" : "",
      validity: validity < 0 ? "Validity is required" : "",
    };

    // Set error state
    setErrors(newErrors);

    // Disable button if there are any validation errors
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    // Check if coinData matches editData (prevent enabling the button)
    const isSameAsEditData =
      editData && JSON.stringify(coinData) === JSON.stringify(editData);

    // Disable button if there are any validation errors or coinData matches editData
    setIsDisabled(hasErrors || isSameAsEditData);
  }, [coinData, editData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCoinData({
      ...coinData,
      [name]: parseInt(value),
    });
  };

  const handleSubmit = () => {
    if (isEdit && editData) {
      dispatch(updateGuruCoinPackage({ id: editData._id, data: coinData }))
        .unwrap()
        .then((res) => {
          if (res) closeModal();
        });
    } else {
      dispatch(createGuruCoinPackage(coinData))
        .unwrap()
        .then((res) => {
          if (res) closeModal();
        });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg mx-4 p-6 rounded-lg shadow-lg max-h-screen overflow-auto">
        {/* Header */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            {isEdit ? "Update Guru Coin Package" : "Create Guru Coin Package"}
          </h2>
        </div>

        <div className="text-sm text-gray-600 space-y-2">
          <p>
            <strong>Paid Amount Calculation:</strong>
          </p>
          <ul className="list-decimal list-inside pl-4 space-y-2">
            <li>
              Start with the <strong>Teacher Value</strong> as the base amount.
            </li>
            <li>
              Add the applicable <strong>Platform Charge</strong>.
            </li>
            <li>
              Calculate <strong>GST</strong> on the Platform Charge: <br />
              <span className="text-blue-600">
                GST = (Platform Charge × GST%) ÷ 100
              </span>
            </li>
            <li>Add the calculated GST to the total.</li>
            <li>
              Subtract any valid <strong>Discount</strong> from the total.
            </li>
          </ul>
          <div className="text-yellow-600 italic mt-2">
            <strong>Formula:</strong> <br /> Paid Amount = Teacher Value +
            (Platform Charge + (Platform Charge × GST ÷ 100)) - Discount
          </div>
          <div className="mt-4">
            <strong>Note:</strong> Ensure all amounts are accurately calculated
            based on the applicable rates and discounts.
          </div>
          <div className="mt-4 text-gray-700">
            {info?.conversionRate?.gcValue && info?.conversionRate?.inrValue ? (
              <div className="text-yellow-600 italic">
                <strong>Conversion Rate:</strong>
                <br />
                <span>
                  {info.conversionRate.gcValue} Coin ={" "}
                  <strong>{info.conversionRate.inrValue} INR</strong>
                </span>
              </div>
            ) : (
              <div className="flex items-center">
                <span className="text-red-600">
                  Conversion Rate is missing. Please set it in the settings.
                </span>
                <a
                  href="/info-settings"
                  className="ml-2 text-blue-500 flex items-center hover:underline"
                >
                  <span>Set now</span>
                  <span className="ml-1">&gt;&gt;</span>
                </a>
              </div>
            )}
          </div>
        </div>

        {/* Input Fields */}
        <div className="space-y-4 mt-4">
          <div className="flex gap-2">
            <div className="flex-1">
              <label htmlFor="coins" className="block text-left text-gray-700">
                Number of Coins
              </label>
              <input
                type="number"
                name="coins"
                value={coinData.coins}
                onChange={handleChange}
                placeholder="Number of Coins"
                className={`w-full px-4 py-2 border ${
                  errors.coins ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {errors.coins && (
                <p className="text-red-500 text-sm text-left ps-1">
                  {errors.coins}
                </p>
              )}
            </div>

            <div className="flex-1">
              <label
                htmlFor="teacherValue"
                className="block text-left text-gray-700"
              >
                Teacher Wallet in INR
              </label>
              <input
                type="number"
                name="teacherValue"
                value={coinData.teacherValue}
                // onChange={handleChange}
                placeholder="Teacher wallet in INR"
                className={`w-full px-4 py-2 border ${
                  errors.teacherValue ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                readOnly
              />
              {errors.teacherValue && (
                <p className="text-red-500 text-sm text-left ps-1">
                  {errors.teacherValue}
                </p>
              )}
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex-1">
              <label
                htmlFor="platformCharge"
                className="block text-left text-gray-700"
              >
                Platform Charge
              </label>
              <input
                type="number"
                name="platformCharge"
                value={coinData.platformCharge}
                onChange={handleChange}
                placeholder="Platform Charge"
                className={`w-full px-4 py-2 border ${
                  errors.platformCharge ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {errors.platformCharge && (
                <p className="text-red-500 text-sm text-left ps-1">
                  {errors.platformCharge}
                </p>
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="gst" className="block text-left text-gray-700">
                GST
              </label>
              <select
                name="gst"
                value={coinData.gst}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value={0}>0%</option>
                <option value={5}>5%</option>
                <option value={12}>12%</option>
                <option value={18}>18%</option>
                <option value={28}>28%</option>
              </select>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex-1">
              <label
                htmlFor="discount"
                className="block text-left text-gray-700"
              >
                Discount (INR)
              </label>
              <input
                type="number"
                name="discount"
                value={coinData.discount}
                onChange={handleChange}
                placeholder="Discount (INR)"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errors.discount && (
                <p className="text-red-500 text-sm text-left ps-1">
                  {errors.discount}
                </p>
              )}
            </div>

            <div className="flex-1">
              <label
                htmlFor="validity"
                className="block text-left text-gray-700"
              >
                Validity (days)
              </label>
              <input
                type="number"
                name="validity"
                value={coinData.validity}
                onChange={handleChange}
                placeholder="Validity (days)"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errors.validity && (
                <p className="text-red-500 text-sm text-left ps-1">
                  {errors.validity}
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="paidAmount"
              className="block text-left text-gray-700"
            >
              Paid Amount
            </label>
            <input
              type="number"
              name="paidAmount"
              value={coinData.paidAmount}
              placeholder="Teacher wallet in INR"
              className={`w-full px-4 py-2 border ${
                errors.paidAmount ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              readOnly
            />
            {errors.paidAmount && (
              <p className="text-red-500 text-sm text-left ps-1">
                {errors.paidAmount}
              </p>
            )}
          </div>
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-yellow-600 rounded-md hover:bg-yellow-700 transition disabled:opacity-50"
            disabled={isDisabled}
          >
            {isEdit ? "Update Package" : "Create Package"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateGuruCoinModal;
