import { PageType } from "../../../enums/page-type.enum";
import PageContent from "../components/PageContent";

const TermsConditionsForParents = () => {
  return (
    <PageContent
      page={PageType.TERMS_CONDITIONS_FOR_PARENTS}
      title="Terms and Conditions content"
    />
  );
};

export default TermsConditionsForParents;
