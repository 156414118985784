// Sidebar menu structure
export const SidebarMenu = [
  {
    menu: "Dashboard",
    path: "/",
  },
  {
    menu: "Admin",
    path: "/admin",
  },
  {
    menu: "Teacher",
    path: "/teacher",
  },
  {
    menu: "Parent",
    path: "/parent",
  },
  {
    menu: "Student",
    path: "/student",
  },
  {
    menu: "Tuition",
    subMenu: [
      {
        menu: "Tuition Classes",
        path: "/tuition-classes",
      },
      {
        menu: "Tuition Requests",
        path: "/tuition-requests",
      },
      {
        menu: "Tuition Job Posts",
        path: "/tuition-job-posts",
      },
    ],
  },
  {
    menu: "Coupon",
    path: "/coupon",
  },
  {
    menu: "Guru Coin",
    path: "/guru-coin",
  },
  {
    menu: "Transactions",
    subMenu: [
      {
        menu: "Guru Coin Transaction",
        path: "/guru-coin-transaction",
      },
      {
        menu: "Payments",
        path: "/payments",
      },
    ],
  },
  {
    menu: "Info Settings",
    path: "/info-settings",
  },
  {
    menu: "Page Content",
    subMenu: [
      {
        menu: "About Us",
        path: "/about-us",
      },
      {
        menu: "Terms And Conditions",
        subMenu: [
          {
            menu: "For Teachers",
            path: "/terms-conditions/teachers",
          },
          {
            menu: "For Parents",
            path: "/terms-conditions/parents",
          },
        ],
      },

      {
        menu: "Privacy Policy",
        path: "/privacy-policy",
      },
    ],
  },

  // {
  //   menu: "Teacher",
  //   subMenu: [
  //     {
  //       menu: "List",
  //       path: "/teacher",
  //     },
  //     {
  //       menu: "Intro Question",
  //       path: "/teacher/intro-questions",
  //     },
  //     {
  //       menu: "Subject Certification",
  //       path: "/teacher/subject-certification",
  //     },
  //   ],
  // },
  // {
  //   menu: "Subjects",
  //   subMenu: [
  //     {
  //       menu: "List",
  //       path: "/subjects/list",
  //     },
  //     {
  //       menu: "Teacher Certification Questions",
  //       path: "/subjects/teacher-certification-questions",
  //     },
  //     {
  //       menu: "Subject Certification",
  //       path: "/subjects/subject-certification",
  //     },
  //   ],
  // },
];
