import { createSlice } from "@reduxjs/toolkit";

interface WindowState {
  loader: boolean;
}

// Define the initial state using that type
const initialState: WindowState = {
  loader: false,
};
const WindowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    startLoading(state) {
      state.loader = true; // Define the updated state using that type
    },
    stopLoading(state) {
      state.loader = false; // Define the updated state using that type
    },
  },
});

// export the reducer that you can import it and get the value anywhere
export const { startLoading, stopLoading } = WindowSlice.actions;

export default WindowSlice.reducer;
