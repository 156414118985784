import React, { useState } from "react";
import { AdminInterface } from "../../interfaces/admin.interface";
import { createAdmin } from "../../redux/slices/AdminSlice";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";

const CreateAdminModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const dispatch: DispatchType = useDispatch();
  const [adminData, setAdminData] = useState<AdminInterface>({
    name: "",
    phoneCode: "+91",
    phoneNumber: null,
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    dispatch(createAdmin(adminData))
      .unwrap()
      .then((res) => {
        if (res) {
          closeModal(); // Close the modal after successful creation
        }
      });
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg mx-4 p-6 rounded-lg shadow-lg max-h-screen overflow-auto">
        {/* Header */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            Create New Admin
          </h2>
        </div>

        {/* Input Fields */}
        <div className="space-y-4">
          <input
            type="text"
            name="name"
            value={adminData.name}
            onChange={handleChange}
            placeholder="Name"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex space-x-2">
            <input
              type="text"
              name="phoneCode"
              value={adminData.phoneCode}
              onChange={handleChange}
              placeholder="Phone Code"
              className="w-1/3 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="number"
              name="phoneNumber"
              value={adminData.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              className="w-2/3 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <input
            type="email"
            name="email"
            value={adminData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            name="password"
            value={adminData.password}
            onChange={handleChange}
            placeholder="Password"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-yellow-600 rounded-md hover:bg-yellow-700 transition disabled:opacity-50"
            disabled={Object.values(adminData).some(
              (value) => value === "" || value === null
            )}
          >
            Create Admin
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAdminModal;
