// ToastUtils.ts

let toastFunctions: any = null;

export const setToastFunctions = (toastFuncs: any) => {
  toastFunctions = toastFuncs;
};

export const successToast = (message: string) => {
  if (toastFunctions) toastFunctions.showToast(message, "success");
};

export const errorToast = (message: string) => {
  if (toastFunctions) toastFunctions.showToast(message, "error");
};

export const warningToast = (message: string) => {
  if (toastFunctions) toastFunctions.showToast(message, "warning");
};

export const infoToast = (message: string) => {
  if (toastFunctions) toastFunctions.showToast(message, "info");
};
