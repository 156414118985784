import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInfo, updateInfo } from "../../redux/slices/InfoSlice";
import { DispatchType } from "../../interfaces/redux.interface";
import { RootState } from "../../redux/store";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import { successToast } from "../../utils/toast.util";

const InfoSettings: React.FC = () => {
  const dispatch: DispatchType = useDispatch();
  const { info, updateStatus } = useSelector((state: RootState) => state.info);

  // Local state for form fields
  const [formValues, setFormValues] = useState({
    teacherSubscription: {
      amount: null,
      validityInDays: null,
    },
    firstTuitionSession: {
      numberOfClasses: null,
      guruCoinCharge: null,
    },
    conversionRate: {
      inrValue: null,
      gcValue: null,
    },
  });

  // State to determine if the button should be disabled
  const [isDisabled, setIsDisabled] = useState(true);

  // Populate form values when info is fetched
  useEffect(() => {
    if (info) {
      setFormValues({ ...formValues, ...info });
    }
    // eslint-disable-next-line
  }, [info]);

  // Check if button should be disabled
  useEffect(() => {
    const isFormUnchanged =
      info && JSON.stringify(formValues) === JSON.stringify(info);
    const hasNullValues = Object.values(formValues.teacherSubscription).some(
      (value) => value === null || value === ""
    );
    const hasFirstTuitionNullValues = Object.values(
      formValues.firstTuitionSession
    ).some((value) => value === null || value === "");
    setIsDisabled(
      isFormUnchanged || hasNullValues || hasFirstTuitionNullValues
    );
  }, [formValues, info]);

  // Fetch info on component mount
  useEffect(() => {
    if (!info) {
      dispatch(startLoading());
      dispatch(getInfo()).finally(() => {
        dispatch(stopLoading());
      });
    }

    //eslint-disable-next-line
  }, [info]);

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: "teacherSubscription" | "firstTuitionSession" | "conversionRate",
    field: string
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: e.target.value ?? "", // Safely handle null or undefined
      },
    }));
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startLoading());
    dispatch(updateInfo(formValues))
      .unwrap()
      .then(() => {
        successToast("Settings updated successfully");
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  return (
    <div className="bg-gray-900 text-yellow-500 flex items-center justify-center p-4 flex-grow overflow-auto">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-2xl">
        <h1 className="text-xl font-semibold mb-4">Update Info Settings</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Teacher Subscription Section */}
          <div>
            <h2 className="text-lg font-semibold mb-2">Teacher Subscription</h2>
            <label className="block mb-2 text-left">
              Amount:
              <input
                type="number"
                value={formValues.teacherSubscription.amount ?? ""}
                onChange={(e) =>
                  handleChange(e, "teacherSubscription", "amount")
                }
                className="w-full p-2 rounded bg-gray-700 text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </label>
            <label className="block text-left">
              Validity (in days):
              <input
                type="number"
                value={formValues.teacherSubscription.validityInDays ?? ""}
                onChange={(e) =>
                  handleChange(e, "teacherSubscription", "validityInDays")
                }
                className="w-full p-2 rounded bg-gray-700 text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </label>
          </div>

          {/* First Tuition Section */}
          <div>
            <h2 className="text-lg font-semibold mb-2">
              First Tuition Session Settings
            </h2>
            <p className="text-sm text-gray-400">
              Configure initial tuition settings for a student-teacher pairing.
              For the first enrollment, the admin sets the number of classes and
              tuition fee. These settings apply only to the initial session. If
              the student continues with the same teacher, different settings
              will apply. These details will reflect on the tuition request page
              for the admin to review before approving or rejecting the request.
            </p>

            <label className="block mb-2 text-left">
              Number of Classes:
              <input
                type="number"
                value={formValues.firstTuitionSession.numberOfClasses ?? ""}
                onChange={(e) =>
                  handleChange(e, "firstTuitionSession", "numberOfClasses")
                }
                className="w-full p-2 rounded bg-gray-700 text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </label>
            <label className="block text-left">
              Guru Coin Charge:
              <input
                type="number"
                value={formValues.firstTuitionSession.guruCoinCharge ?? ""}
                onChange={(e) =>
                  handleChange(e, "firstTuitionSession", "guruCoinCharge")
                }
                className="w-full p-2 rounded bg-gray-700 text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </label>
          </div>

          {/* Conversion Rate Section */}
          <div>
            <h2 className="text-lg font-semibold mb-2">Conversion Rate</h2>
            <p className="text-sm text-gray-400">
              Note: These settings will reflect during the Guru Coin package
              creation.
            </p>
            <label className="block mb-2 text-left">
              INR Value:
              <input
                type="number"
                value={formValues.conversionRate.inrValue ?? ""}
                onChange={(e) => handleChange(e, "conversionRate", "inrValue")}
                className="w-full p-2 rounded bg-gray-700 text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </label>
            <label className="block text-left">
              Guru Coin Value:
              <input
                type="number"
                value={formValues.conversionRate.gcValue ?? ""}
                onChange={(e) => handleChange(e, "conversionRate", "gcValue")}
                className="w-full p-2 rounded bg-gray-700 text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full py-2 px-4 rounded focus:outline-none focus:ring-2 ${
              isDisabled
                ? "bg-yellow-500 opacity-50 hover:bg-yellow-500 text-gray-300 cursor-not-allowed"
                : "bg-yellow-500 text-gray-900 hover:bg-yellow-400 focus:ring-yellow-500"
            }`}
            disabled={isDisabled}
          >
            {updateStatus === "loading" ? "Updating..." : "Update Settings"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default InfoSettings;
