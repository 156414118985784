import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GuruCoinForm,
  GuruCoinInterface,
} from "../../interfaces/guruCoin.interface";
import { get, post, put } from "../ApiHelper";

interface GuruCoinState {
  status: string;
  guruCoinPackages: GuruCoinInterface[];
}

const initialState: GuruCoinState = {
  status: "idle",
  guruCoinPackages: [],
};

// Async actions
export const getGuruCoinPackages = createAsyncThunk(
  "get-guru-coins",
  async () => {
    try {
      const response = await get("/guru-coin/all");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const createGuruCoinPackage = createAsyncThunk(
  "create-guru-coin",
  async (data: GuruCoinForm) => {
    try {
      const response = await post("/guru-coin/create", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateGuruCoinPackage = createAsyncThunk(
  "update-guru-coin",
  async ({ id, data }: { id: string; data: Partial<GuruCoinForm> }) => {
    try {
      const response = await put(`/guru-coin/update/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Slice
const GuruCoinSlice = createSlice({
  name: "guruCoin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get all Guru Coins
      .addCase(getGuruCoinPackages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGuruCoinPackages.fulfilled, (state, action) => {
        state.guruCoinPackages = action.payload;
        state.status = "success";
      })
      .addCase(getGuruCoinPackages.rejected, (state) => {
        state.status = "reject";
      })

      // Create Guru Coin
      .addCase(createGuruCoinPackage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGuruCoinPackage.fulfilled, (state, action) => {
        state.guruCoinPackages.push(action.payload);
        state.status = "success";
      })
      .addCase(createGuruCoinPackage.rejected, (state) => {
        state.status = "reject";
      })

      // Update Guru Coin
      .addCase(updateGuruCoinPackage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateGuruCoinPackage.fulfilled, (state, action) => {
        state.guruCoinPackages = state.guruCoinPackages.map((coin) =>
          coin._id === action.payload._id ? action.payload : coin
        );
        state.status = "success";
      })
      .addCase(updateGuruCoinPackage.rejected, (state) => {
        state.status = "reject";
      });
  },
});

export default GuruCoinSlice.reducer;
